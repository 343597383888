import React from 'react';
import styled from 'styled-components';
import { Table, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TableHeaderCell = styled(Table.HeaderCell)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 20px !important;
  line-height: 20px !important;
  text-align: ${props => (props.centered === 'true' ? 'center' : 'left')};
`;

const CompactHeaderCell = styled(TableHeaderCell)`
  width: 3% !important;
`;

const HeaderCellTrigger = styled.div`
  text-align: ${props => (props.centered === 'true' ? 'center' : 'left')};
  float: left;
`;

const DossierTableHeader = ({ size, column, direction, user, sort }) => {
  return size !== 's' ? (
    <Table.Header>
      <Table.Row>
        <TableHeaderCell
          width={1}
          sorted={column === 'iPKDossier' ? direction : null}
          onClick={sort('iPKDossier')}
        >
          Dossier
        </TableHeaderCell>
        {size === 'l' && (
          <>
            <CompactHeaderCell
              centered="true"
              sorted={column === 'bUrgent' ? direction : null}
              onClick={sort('bUrgent')}
            >
              <Popup
                content="Urgent"
                position="top left"
                trigger={<HeaderCellTrigger centered="true">U.</HeaderCellTrigger>}
              />
            </CompactHeaderCell>
            <CompactHeaderCell
              centered="true"
              sorted={column === 'bReglementaire' ? direction : null}
              onClick={sort('bReglementaire')}
            >
              <Popup
                content="Réglementaire"
                position="top left"
                trigger={<HeaderCellTrigger centered="true">R.</HeaderCellTrigger>}
              />
            </CompactHeaderCell>
            <CompactHeaderCell
              centered="true"
              sorted={column === 'bSpecifique' ? direction : null}
              onClick={sort('bSpecifique')}
            >
              <Popup
                content="Spécifique"
                position="top left"
                trigger={<HeaderCellTrigger centered="true">S.</HeaderCellTrigger>}
              />
            </CompactHeaderCell>
            <CompactHeaderCell
              centered="true"
              sorted={column === 'bPrioritaire' ? direction : null}
              onClick={sort('bPrioritaire')}
            >
              <Popup
                content="Prioritaire"
                position="top left"
                trigger={<HeaderCellTrigger centered="true">P.</HeaderCellTrigger>}
              />
            </CompactHeaderCell>
          </>
        )}
        {size === 'l' || size === 's' ? (
          <CompactHeaderCell
            sorted={column === 'sNature' ? direction : null}
            onClick={sort('sNature')}
          >
            <Popup
              content="Nature"
              position="top left"
              trigger={<HeaderCellTrigger centered>N.</HeaderCellTrigger>}
            />
          </CompactHeaderCell>
        ) : null}
        <TableHeaderCell
          width={6}
          sorted={column === 'sConcerne' ? direction : null}
          onClick={sort('sConcerne')}
        >
          Concerne
        </TableHeaderCell>
        {size !== 's' ? (
          <TableHeaderCell
            width={2}
            sorted={column === 'sEtat' ? direction : null}
            onClick={sort('sEtat')}
          >
            Etat du dossier
          </TableHeaderCell>
        ) : null}
        {user.iFKClient === 99 && (
          <CompactHeaderCell
            sorted={column === 'sFKIntervenant' ? direction : null}
            onClick={sort('sFKIntervenant')}
          >
            Int.
          </CompactHeaderCell>
        )}
        <CompactHeaderCell
          sorted={column === 'iPriorite' ? direction : null}
          onClick={sort('iPriorite')}
        >
          <Popup
            content="Priorité"
            position="top left"
            trigger={<HeaderCellTrigger centered="true">P.</HeaderCellTrigger>}
          />
        </CompactHeaderCell>
      </Table.Row>
    </Table.Header>
  ) : null;
};

DossierTableHeader.defaultProps = {
  column: null,
  direction: null
};

DossierTableHeader.propTypes = {
  sort: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.string.isRequired,
  column: PropTypes.string,
  direction: PropTypes.string
};

export default DossierTableHeader;
