/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import filenamify from 'filenamify';

import { Link } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';

import TopBar from '../components/Core/Layout/TopBar';

const Screen = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.backgroundLight};
`;

const MyContainer = styled(Container)`
  background: ${props => props.theme.colors.backgroundLight};
  width: 90% !important;
  margin-top: 50px;
  padding: 20px 0 0;
`;

const ClientBox = styled(Segment)`
  width: 180px;
  margin: 15px 0 15px !important;
`;

const ClientLogo = styled.img`
  display: block;
  margin: 0 auto 30px;
  height: 60px;
  width: auto;
  max-width: 100%;
`;

const ClientName = styled.div`
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;

const ClientDossiersNb = styled.div`
  font-weight: bold;
`;

function importAll(r) {
  const images = {};
  r.keys().map(item => {
    images[item.replace('./', '')] = r(item);
    return null;
  });
  return images;
}

const logos = importAll(require.context('../assets/img/clients', false, /\.(png|jpe?g|svg)$/));

class Clients extends Component {
  render() {
    const { tClient, tDossier } = this.props;
    const tClientOrder = ['ADEP', 'ARAIR', 'AGIRADOM', 'SANTELYS', 'ALLP', 'APARD'];
    return (
      <Screen>
        <TopBar />
        <MyContainer fluid>
          <Grid>
            <Grid.Row style={{ marginLeft: 15 }}>
              <Grid>
                {tClient.map(client => {
                  if (tClientOrder.includes(client.sNomAbrege)) {
                    return (
                      <Link
                        key={client.sNomAbrege}
                        target="_blank"
                        to={`/client/${client.iPKClient}`}
                      >
                        <ClientBox>
                          <ClientLogo
                            src={
                              logos[
                                `${filenamify(client.sNomAbrege.trim(), { replacement: '' })
                                  .toLowerCase()
                                  .split(' ')
                                  .join('-')}.png`
                              ]
                            }
                            alt={`${filenamify(client.sNomAbrege.trim(), { replacement: '' })
                              .toLowerCase()
                              .split(' ')
                              .join('-')}.png`}
                          />
                          <ClientName>{client.sNomAbrege}</ClientName>
                          <ClientDossiersNb>
                            {tDossier.filter(x => x.iFKClient === client.iPKClient).length} dossiers
                          </ClientDossiersNb>
                        </ClientBox>
                      </Link>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid.Row>
            {tClient.map(client => {
              if (!tClientOrder.includes(client.sNomAbrege)) {
                return (
                  <Link key={client.sNomAbrege} target="_blank" to={`/client/${client.iPKClient}`}>
                    <ClientBox>
                      <ClientLogo
                        src={
                          logos[
                            `${filenamify(client.sNomAbrege.trim(), { replacement: '' })
                              .toLowerCase()
                              .split(' ')
                              .join('-')}.png`
                          ]
                        }
                        alt={`${filenamify(client.sNomAbrege.trim(), { replacement: '' })
                          .toLowerCase()
                          .split(' ')
                          .join('-')}.png`}
                      />
                      <ClientName>{client.sNomAbrege}</ClientName>
                      <ClientDossiersNb>
                        {tDossier.filter(x => x.iFKClient === client.iPKClient).length} dossiers
                      </ClientDossiersNb>
                    </ClientBox>
                  </Link>
                );
              }
              return null;
            })}
          </Grid>
        </MyContainer>
      </Screen>
    );
  }
}

Clients.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  tClient: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  tClient: state.config.tClient,
  tDossier: state.config.tDossier
});

export default connect(mapStateToProps)(Clients);
