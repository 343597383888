import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { setOnlineMode, setOfflineMode } from './actions/network';

import { loadConfig, getCategorieDossier } from './actions/config';

import Routes from './Routes';

import theme from './theme';

import UniversCondensed from './assets/fonts/Univers-Condensed.otf';
import UniversCondensedBold from './assets/fonts/Univers-Condensed-Bold.otf';
import Futura from './assets/fonts/Futura-LT-Book.otf';

import 'semantic-ui-css/semantic.min.css';
import 'react-reflex/styles.css';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.backgroundLight};
  }

  body {
    margin: 0;
    padding: 0;
    min-width: ${window.innerWidth}px;
    min-height: ${window.innerHeight}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "UniversCondensed", sans-serif !important;
    font-size: 14px;
    line-height: 1.2857142857142857142857142857143em;
    color: ${props => props.theme.colors.textBlack} !important;
    background: ${props => props.theme.colors.backgroundLight} !important;
    overscroll-behavior: contain;
  }

  h1, h2, h3, h4,
  strong {
    font-family: "UniversCondensedBold";
  }

  h4 {
    margin: 20px 0 -10px !important;
    font-family: "UniversCondensed";
  }

  label,
  input,
  textarea {
    font-family: "Futura", sans-serif !important;
  }

  .ui.button {
    font-family: "Futura", sans-serif !important;
    font-weight: 400 !important;
  }

  a {
    color: ${props => props.theme.colors.primary};

    &:focus,
    &:visited {
      color: ${props => props.theme.colors.primary};
      outline: none;
    }
  }

  tbody {
    overflow: auto;
  }

  table.small thead,
  table.small tbody tr {
    display: table;
    width: 100%;
  }

  table .ui.checkbox {
    height: 13px;
    min-height: 13px;
    max-height: 13px;
    width: 13px;
    min-width: 13px;
    max-width: 13px;
  }

  .ui[class*="very compact"].table td {
    padding: 0 !important;
  }

  .tiny .dropdown.icon {
    top: 0.45em !important;
  }

  .tiny .dropdown {
    min-height: 2em !important;
  }

  table.selectable tr {
    cursor: pointer;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .ui.dimmer {
    z-index: 2000 !important;
  }

  .ui.grid>.row {
    padding: 0 !important;
  }

textarea,
.ui.input input {
  font-family: "UniversCondensed" !important;
}

.react-contextmenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 10px #999999;
}
.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}
.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: black;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}
.react-contextmenu-item.react-contextmenu-item a {
  color: ${props => props.theme.colors.textBlack};
}
.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  & a {
    color: white;
  }
  background-color: ${props => props.theme.colors.blue};
  border-color: #20a0ff;
  text-decoration: none;
}
.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}
.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-datepicker-component .react-datepicker-input .button-wrapper .input-button {
  padding: 10px;
  margin: 0 !important;
}
.ui.modal>.close {
  color: ${props => props.theme.colors.textBlack} !important;
}
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    const { dispatch, isAuthenticated, user } = this.props;

    if (isAuthenticated) {
      dispatch(loadConfig());
      dispatch(getCategorieDossier(user.data.iFKClient));
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onNetworkUpdate = ({ isOffline }) => {
    const { dispatch } = this.props;

    if (isOffline) {
      dispatch(setOfflineMode());
    } else {
      dispatch(setOnlineMode());
    }
  };

  render() {
    const { history } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes history={history} animatedTransitions />
        </Router>
        <GlobalStyle />
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  offline: state.network.offline,
  isAuthenticated: state.user.isAuthenticated,
  user: state.user
});

export default connect(mapStateToProps)(App);
