/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
import { Segment, Grid, Loader, Form, Checkbox } from 'semantic-ui-react';

import DossierTable from './Table/DossierTable';
import TacheTable from './Table/TacheTable';

const getBackgroundColor = (colors, selected, system) => {
  if (selected) {
    return colors.blueAGIR;
  }

  if (system) {
    return colors.backgroundLight;
  }

  return colors.white;
};

const Title = styled.h4`
  margin-top: 10px;
`;

const CategoriesContainer = styled.div`
  position: relative;
  z-index: 100;
  float: right;
  width: 72%;
  text-align: right;
`;

const Category = styled.button`
  margin: 0 0 5px 5px;
  padding: 5px;
  height: 30px;
  color: ${props => (props.selected ? props.theme.colors.white : 'inherit')};
  background: ${props => getBackgroundColor(props.theme.colors, props.selected, props.system)};
  border: 1px solid ${props => props.theme.colors.borderLight};
  outline: none;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueAGIR};
  }
`;

const CategoryNb = styled.span`
  display: inline-block;
  margin: 0 0 0 3px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.colors.textBlack};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 50%;
`;

class DossierUrgent extends Component {
  constructor(props) {
    super(props);

    const { tDossier, syncHeight } = this.props;

    this.state = {
      syncHeight,
      priorityShow: true,
      tTache: [],
      tStateDossier: tDossier,
      currentCategory: -1
    };

    this.resizing = false;
  }

  componentDidUpdate(prevProps) {
    const { tDossier, syncHeight } = this.props;
    const { currentCategory, priorityShow } = this.state;
    let orderedDossier = tDossier;
    if (prevProps.tDossier !== tDossier) {
      if (currentCategory > 0) {
        orderedDossier = orderedDossier.filter(
          dossier => dossier.iFKDossierCategorie === currentCategory
        );
      }
      if (!priorityShow) {
        orderedDossier = orderedDossier.filter(d => d.bUrgent === 1);
      } else {
        orderedDossier = orderedDossier.filter(d => d.bUrgent === 1 || d.bPrioritaire === 1);
      }

      this.setState({ tStateDossier: orderedDossier });
    }

    if (syncHeight !== prevProps.syncHeight) {
      this.setState({ syncHeight });
    }
  }

  onShowPriorityChange = () => {
    const { priorityShow, currentCategory } = this.state;
    const { tDossier } = this.props;
    let orderedDossier = tDossier;
    if (currentCategory > 0) {
      orderedDossier = orderedDossier.filter(
        dossier => dossier.iFKDossierCategorie === currentCategory
      );
    }
    if (priorityShow) {
      orderedDossier = orderedDossier.filter(d => d.bUrgent === 1);
    } else {
      orderedDossier = orderedDossier.filter(d => d.bUrgent === 1 || d.bPrioritaire === 1);
    }
    this.setState({
      priorityShow: !priorityShow,
      tStateDossier: orderedDossier
    });
  };

  onSelectDossier = iPKDossier => {
    const { tStateDossier } = this.state;
    const dossier = tStateDossier.filter(x => x.iPKDossier === iPKDossier)[0];
    this.setState({
      tTache: dossier.AssPlanningTasks.concat(dossier.AssParentPlanningTasks)
    });
  };

  onResize = (event, { size }) => {
    const nextVersion = window.document.getElementById('content-next-version');
    if (size.height > nextVersion.clientHeight - 111) {
      this.resizing = true;
      this.setState({ syncHeight: size.height });
    }
  };

  onResizeStop = (event, { size }) => {
    const { handleSyncHeight } = this.props;

    handleSyncHeight(size.height);
  };

  handleCategoryClick = (evt, currentCategory) => {
    evt.preventDefault();
    const { tDossier } = this.props;
    const { priorityShow } = this.state;
    let orderedDossier = tDossier;

    if (currentCategory === -1) {
      orderedDossier = tDossier;
    } else if (currentCategory === 0) {
      orderedDossier = tDossier.filter(dossier => dossier.iFKDossierCategorie === null);
    } else {
      orderedDossier = tDossier.filter(dossier => dossier.iFKDossierCategorie === currentCategory);
    }

    if (!priorityShow) {
      orderedDossier = orderedDossier.filter(d => d.bUrgent === 1);
    } else {
      orderedDossier = orderedDossier.filter(d => d.bUrgent === 1 || d.bPrioritaire === 1);
    }

    this.setState({
      tStateDossier: orderedDossier,
      currentCategory
    });
  };

  render() {
    const { tCategorieDossier, tDossier } = this.props;
    const { syncHeight, tTache, priorityShow, tStateDossier, currentCategory } = this.state;
    return (
      <>
        <Title>
          Dossiers urgents (
          {tDossier &&
            tDossier.filter(dossier =>
              priorityShow
                ? dossier.bUrgent === 1 || dossier.bPrioritaire === 1
                : dossier.bUrgent === 1
            ).length}
          )
        </Title>
        <Segment>
          <Resizable
            className="box"
            width={600}
            height={syncHeight}
            onResize={this.onResize}
            onResizeStop={this.onResizeStop}
            resizeHandles={['s']}
          >
            <Grid.Column>
              <Grid columns={2}>
                <Grid.Column width={13}>
                  {tCategorieDossier && tCategorieDossier.length > 0 && tDossier && (
                    <CategoriesContainer>
                      <Category
                        selected={currentCategory === -1}
                        system
                        onClick={evt => this.handleCategoryClick(evt, -1)}
                      >
                        Toutes les catégories{' '}
                        <CategoryNb>
                          {
                            tDossier.filter(dossier =>
                              priorityShow
                                ? dossier.bUrgent === 1 || dossier.bPrioritaire === 1
                                : dossier.bUrgent === 1
                            ).length
                          }
                        </CategoryNb>
                      </Category>
                      <Category
                        selected={currentCategory === 0}
                        system
                        onClick={evt => this.handleCategoryClick(evt, 0)}
                      >
                        Aucune catégorie{' '}
                        <CategoryNb>
                          {
                            tDossier.filter(
                              dossier =>
                                dossier.iFKDossierCategorie === null &&
                                (priorityShow
                                  ? dossier.bUrgent === 1 || dossier.bPrioritaire === 1
                                  : dossier.bUrgent === 1)
                            ).length
                          }
                        </CategoryNb>
                      </Category>
                      {tCategorieDossier.map(category => (
                        <Category
                          key={category.iPKDossierCategorie}
                          onClick={evt =>
                            this.handleCategoryClick(evt, category.iPKDossierCategorie)
                          }
                          selected={currentCategory === category.iPKDossierCategorie}
                        >
                          {category.sLibelle}
                          <CategoryNb>
                            {
                              tDossier.filter(
                                dossier =>
                                  dossier.iFKDossierCategorie === category.iPKDossierCategorie &&
                                  (priorityShow
                                    ? dossier.bUrgent === 1 || dossier.bPrioritaire === 1
                                    : dossier.bUrgent === 1)
                              ).length
                            }
                          </CategoryNb>
                        </Category>
                      ))}
                    </CategoriesContainer>
                  )}

                  <Form>
                    <Form.Group>
                      <Form.Field
                        id="priorite"
                        control={Checkbox}
                        className="tiny"
                        data-tooltip='Ajoutes les dossiers indiqués "priorité haute" par les clients.'
                        style={{ padding: '2px 0px 0 4px', marginTop: 5 }}
                        label={
                          <label htmlFor="priorite">
                            {' '}
                            Afficher les dossiers &laquo;priorité haute&raquo;
                          </label>
                        }
                        onChange={this.onShowPriorityChange}
                        checked={priorityShow}
                      />
                    </Form.Group>
                  </Form>

                  {tStateDossier === null ? (
                    <Loader active>Chargement...</Loader>
                  ) : (
                    <DossierTable
                      tDossier={tStateDossier.sort((a, b) => a.iPriorite - b.iPriorite)}
                      onSelectDossier={this.onSelectDossier}
                      maxHeight={`${syncHeight}px`}
                      categories
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={3}>
                  <TacheTable tTache={tTache} top={40} maxHeight={`${syncHeight + 40}px`} />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Resizable>
        </Segment>
      </>
    );
  }
}

DossierUrgent.defaultProps = {
  tDossier: null
};

DossierUrgent.propTypes = {
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tCategorieDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  syncHeight: PropTypes.number.isRequired,
  handleSyncHeight: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  tCategorieDossier: state.config.tCategorieDossier
});

export default connect(mapStateToProps)(DossierUrgent);
