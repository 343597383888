/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import filenamify from 'filenamify';

import { Loader, Grid, Container, Segment, Form, Select, Icon } from 'semantic-ui-react';
import 'react-resizable/css/styles.css';

import {
  loadConfig,
  getDossiers,
  getDossiersReglementaire,
  getCategorieDossier
} from '../actions/config';

import TopBar from '../components/Core/Layout/TopBar';
import DossierReglementaire from '../components/Client/DossierReglementaire';
import DossierUrgent from '../components/Client/DossierUrgent';
import DossierAutre from '../components/Client/DossierAutre';
import DossierProchaineVersion from '../components/Client/DossierProchaineVersion';

const getBackgroundColor = (colors, selected, system) => {
  if (selected) {
    return colors.blueAGIR;
  }

  if (system) {
    return colors.backgroundLight;
  }

  return colors.white;
};

const Screen = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.backgroundLight};
`;

const MyContainer = styled(Container)`
  background: ${props => props.theme.colors.backgroundLight};
  opacity: ${props => (props.loading ? '0.3' : '1')}
  width: 90% !important;
  margin-top: 35px;
  padding: 20px 0 40px;
`;

const Logo = styled.img`
  margin: 0px auto;
  margin-bottom: 10px;
  display: block;
  height: 90px;
`;

const Actionbutton = styled.button`
  margin: 0 0 5px 5px;
  padding: 10px;
  height: 40px;
  color: ${props => (props.selected ? props.theme.colors.white : 'inherit')};
  background: ${props => getBackgroundColor(props.theme.colors, props.selected, props.system)};
  border: 1px solid ${props => props.theme.colors.borderLight};
  outline: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueAGIR};
  }
`;

const importAll = r => {
  const images = {};
  r.keys().map(item => {
    images[item.replace('./', '')] = r(item);
    return null;
  });
  return images;
};

const logos = importAll(require.context('../assets/img/clients', false, /\.(png|jpe?g|svg)$/));

const getLogoSrc = client => {
  return logos[
    `${filenamify(client.sNomAbrege.trim(), { replacement: '' })
      .toLowerCase()
      .split(' ')
      .join('-')}.png`
  ];
};

class InfoClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tStateDossier: null,
      syncHeight: 367,
      loading: false
    };
  }

  componentDidMount() {
    const {
      dispatch,
      user,
      match: {
        params: { iPKClient }
      }
    } = this.props;

    dispatch(loadConfig());
    dispatch(getDossiersReglementaire());
    if (user.iFKClient === 99) {
      dispatch(getDossiers(parseInt(iPKClient, 10) || 99, false));
      dispatch(getCategorieDossier(parseInt(iPKClient, 10) || 99));
    } else if (user) {
      dispatch(getDossiers(user.iFKClient, false));
      dispatch(getCategorieDossier(user.iFKClient));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { iPKClient }
      },
      tDossier,
      user
    } = this.props;

    let iPKClientFiltered = user.iFKClient;

    if (tDossier !== prevProps.tDossier) {
      if (user.iRole === 1) {
        iPKClientFiltered = iPKClient || iPKClientFiltered;
      }
      this.setState({
        tStateDossier: tDossier.filter(x => {
          return (
            x.iFKClient === parseInt(iPKClientFiltered, 10) ||
            (x.AssDevi &&
              x.AssDevi.sListeMutualisation &&
              x.AssDevi.sListeMutualisation
                .split(',')
                .map(s => s.split('-')[0])
                .includes(iPKClientFiltered.toString()))
          );
        }),
        loading: false
      });
    }
  }

  reload = () => {
    const {
      dispatch,
      user,
      match: {
        params: { iPKClient }
      }
    } = this.props;

    this.setState({ loading: true }, () => {
      dispatch(loadConfig());
      dispatch(getDossiersReglementaire());
      if (user.iFKClient === 99) {
        dispatch(getDossiers(parseInt(iPKClient, 10) || 99, false));
        dispatch(getCategorieDossier(parseInt(iPKClient, 10) || 99));
      } else if (user) {
        dispatch(getDossiers(user.iFKClient, false));
        dispatch(getCategorieDossier(user.iFKClient));
      }
    });
  };

  handleSyncHeight = height => {
    this.setState({ syncHeight: height });
  };

  render() {
    const { tStateDossier, syncHeight, loading } = this.state;
    const {
      match: { params },
      user,
      tClient,
      tDossierReglementaire
    } = this.props;

    const client =
      user.iRole === 1 && params.iPKClient
        ? tClient.filter(x => x.iPKClient === parseInt(params.iPKClient, 10))[0]
        : tClient.filter(x => x.iPKClient === user.iFKClient)[0];

    return (
      <Screen>
        <TopBar />
        {tStateDossier ? (
          <>
            {loading && <Loader active>Chargement des dossiers</Loader>}
            <MyContainer loading={loading} fluid>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column style={{ textAlign: 'center' }} width={4}>
                    <Segment style={{ marginTop: 42, height: 204 }}>
                      <Logo user={user} src={getLogoSrc(client)} alt={client.sNomAbrege} />

                      {user.iRole === 1 && (
                        <Form.Field
                          id="iEtat"
                          control={Select}
                          options={tClient.map(c => ({
                            key: c.iPKClient,
                            value: c.iPKClient,
                            text: c.sNomAbrege
                          }))}
                          placeholder="Selectionner un client"
                          value={params.iPKClient ? parseInt(params.iPKClient, 10) : user.iFKClient}
                          onChange={(evt, data) => {
                            window.location.replace(
                              `${window.location.protocol}//${window.location.hostname}${window
                                .location.port && `:${window.location.port}`}/client${
                                data.value === 99 ? '' : `/${data.value}`
                              }`
                            );
                          }}
                        />
                      )}
                      <Grid.Row style={{ marginTop: user.iRole === 1 ? 20 : 40 }}>
                        <Grid columns={1}>
                          <Grid.Column style={{ padding: 0 }} width={16}>
                            <Actionbutton
                              data-tooltip="Recharge les données tout en gardant les filtres actuels"
                              data-position="top left"
                              onClick={this.reload}
                            >
                              <Icon name="sync" /> Recharger les données
                            </Actionbutton>
                          </Grid.Column>
                        </Grid>
                      </Grid.Row>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <DossierReglementaire
                      tDossier={_.sortBy(
                        user.iRole === 1
                          ? tDossierReglementaire.filter(
                              x => x.bReglementaire === 1 && x.iFKDossierMaitre === null
                            )
                          : tDossierReglementaire.filter(
                              x =>
                                x.bReglementaire === 1 &&
                                x.iFKDossierMaitre === null &&
                                !x.bMasque &&
                                !x.bPrive
                            ),
                        'AssEtatDossier.iPKDossierEtat'
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <DossierUrgent
                      tDossier={
                        tStateDossier &&
                        tStateDossier.filter(x => x.bUrgent === 1 || x.bPrioritaire === 1)
                      }
                      handleSyncHeight={this.handleSyncHeight}
                      syncHeight={syncHeight}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <DossierProchaineVersion
                      iPKClient={
                        user.iRole && params.iPKClient
                          ? parseInt(params.iPKClient, 10)
                          : user.iFKClient
                      }
                      handleSyncHeight={this.handleSyncHeight}
                      syncHeight={syncHeight}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <DossierAutre
                      iPKClient={
                        user.iFKClient === 99 ? parseInt(params.iPKClient, 10) : user.iFKClient
                      }
                      tDossier={tStateDossier && tStateDossier.filter(x => x.bUrgent === 0)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </MyContainer>
          </>
        ) : (
          <Loader active>Chargement des dossiers</Loader>
        )}
      </Screen>
    );
  }
}

InfoClient.defaultProps = {
  tDossierReglementaire: []
};

InfoClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  tDossier: PropTypes.arrayOf(PropTypes.any).isRequired,
  tDossierReglementaire: PropTypes.arrayOf(PropTypes.any),
  tClient: PropTypes.arrayOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  tClient: state.config.tClient,
  tDossier: state.config.tDossier,
  tDossierReglementaire: state.config.tDossierReglementaire
});

export default connect(mapStateToProps)(InfoClient);
