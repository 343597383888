import React from 'react';
import { Table, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CellContent } from '../TableStyle';

const CellsConcerne = ({ sConcerne }) => {
  return (
    <Table.Cell
      width={6}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
    >
      <Popup
        position="top center"
        positionFixed
        eventsEnabled={false}
        content={sConcerne}
        trigger={<CellContent>{sConcerne}</CellContent>}
      />
    </Table.Cell>
  );
};

CellsConcerne.propTypes = {
  sConcerne: PropTypes.string.isRequired
};

export default CellsConcerne;
