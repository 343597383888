import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, Grid, Segment, Image } from 'semantic-ui-react';

import ErreurImg from '../assets/img/erreur.svg';

const Screen = styled.div`
  padding-top: 40px;
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.backgroundLight};
`;

const ErrorText = styled.div`
  padding: 2rem 0;
  font-size: 24px;
  text-align: center;
`;

const BtnRestart = styled.button`
  margin: 0 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  height: 40px;
  width: 302px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const Erreur404 = ({ user }) => (
  <Screen>
    <Container>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment padded>
              <Image src={ErreurImg} />

              <ErrorText>Oops ! Une erreur est survenue... :(</ErrorText>
              <BtnRestart
                onClick={evt => {
                  evt.preventDefault();

                  let destination = '/';

                  if (user.sPKIntervenant !== -1) {
                    destination = `/autologin/${user.sPKIntervenant}`;
                  }

                  window.location.href = destination;
                }}
              >
                {user.sPKIntervenant !== -1
                  ? 'Cliquez-ici pour retourner à votre planning'
                  : `Cliquez-ici pour retourner à l'accueil`}
              </BtnRestart>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Screen>
);

const mapStateToProps = state => ({
  user: state.user.data
});

Erreur404.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps)(Erreur404);
