import React from 'react';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CompactCell, CellContent, ColorCheckbox, DossierIcon } from '../TableStyle';

const CellbUrgent = ({ user, iPKDossier, bUrgent, handleChange }) => {
  if (user.iFKClient === 99) {
    return (
      <CompactCell style={{ color: 'red' }}>
        <Popup
          trigger={
            <CellContent centered="true">
              <ColorCheckbox
                color="red"
                onChange={() => handleChange(iPKDossier, !!bUrgent)}
                checked={!!bUrgent}
              />
            </CellContent>
          }
          content="Change le statut urgent du dossier"
        />
      </CompactCell>
    );
  }
  return (
    <CompactCell style={{ color: 'red' }}>
      {bUrgent ? (
        <Popup
          position="top center"
          trigger={
            <CellContent centered="true">
              <DossierIcon name="check" color="red" />
            </CellContent>
          }
          content="Dossier urgent"
        />
      ) : null}
    </CompactCell>
  );
};

CellbUrgent.defaultProps = {
  bUrgent: 0
};

CellbUrgent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  bUrgent: PropTypes.number,
  iPKDossier: PropTypes.number.isRequired
};

export default CellbUrgent;
