export const NETWORK_ONLINE_REQUEST = 'NETWORK_ONLINE_REQUEST';
export const NETWORK_ONLINE_SUCCESS = 'NETWORK_ONLINE_SUCCESS';
export const NETWORK_ONLINE_FAILURE = 'NETWORK_ONLINE_FAILURE';

export const NETWORK_OFFLINE_REQUEST = 'NETWORK_OFFLINE_REQUEST';
export const NETWORK_OFFLINE_SUCCESS = 'NETWORK_OFFLINE_SUCCESS';
export const NETWORK_OFFLINE_FAILURE = 'NETWORK_OFFLINE_FAILURE';

export const LOADING_ACTIVATE_REQUEST = 'LOADING_ACTIVATE_REQUEST';
export const LOADING_ACTIVATE_SUCCESS = 'LOADING_ACTIVATE_SUCCESS';
export const LOADING_ACTIVATE_FAILURE = 'LOADING_ACTIVATE_FAILURE';

export const LOADING_DEACTIVATE_REQUEST = 'LOADING_DEACTIVATE_REQUEST';
export const LOADING_DEACTIVATE_SUCCESS = 'LOADING_DEACTIVATE_SUCCESS';
export const LOADING_DEACTIVATE_FAILURE = 'LOADING_DEACTIVATE_FAILURE';

export const USER_AUTOLOGIN_REQUEST = 'USER_AUTOLOGIN_REQUEST';
export const USER_AUTOLOGIN_SUCCESS = 'USER_AUTOLOGIN_SUCCESS';
export const USER_AUTOLOGIN_FAILURE = 'USER_AUTOLOGIN_FAILURE';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE';

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE';

export const CONFIG_LOAD_REQUEST = 'CONFIG_LOAD_REQUEST';
export const CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS';
export const CONFIG_LOAD_FAILURE = 'CONFIG_LOAD_FAILURE';

export const CONFIG_GET_DOSSIERS_REQUEST = 'CONFIG_GET_DOSSIERS_REQUEST';
export const CONFIG_GET_DOSSIERS_SUCCESS = 'CONFIG_GET_DOSSIERS_SUCCESS';
export const CONFIG_GET_DOSSIERS_FAILURE = 'CONFIG_GET_DOSSIERS_FAILURE';

export const CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST =
  'CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST';
export const CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS =
  'CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS';
export const CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE =
  'CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE';

export const CONFIG_GET_CATEGORIE_DOSSIER_REQUEST = 'CONFIG_GET_CATEGORIE_DOSSIER_REQUEST';
export const CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS = 'CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS';
export const CONFIG_GET_CATEGORIE_DOSSIER_FAILURE = 'CONFIG_GET_CATEGORIE_DOSSIER_FAILURE';

export const CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST = 'CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST';
export const CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS = 'CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS';
export const CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE = 'CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE';

export const CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST = 'CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST';
export const CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS = 'CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS';
export const CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE = 'CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE';

export const CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST = 'CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST';
export const CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS = 'CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS';
export const CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE = 'CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE';

export const SET_DOSSIER_REQUEST = 'SET_DOSSIER_REQUEST';
export const SET_DOSSIER_SUCCESS = 'SET_DOSSIER_SUCCESS';
export const SET_DOSSIER_FAILURE = 'SET_DOSSIER_FAILURE';

export const SET_PRIORITE_DOSSIER_REQUEST = 'SET_PRIORITE_DOSSIER_REQUEST';
export const SET_PRIORITE_DOSSIER_SUCCESS = 'SET_PRIORITE_DOSSIER_SUCCESS';
export const SET_PRIORITE_DOSSIER_FAILURE = 'SET_PRIORITE_DOSSIER_FAILURE';

export const TEAM_LOAD_REQUEST = 'TEAM_LOAD_REQUEST';
export const TEAM_LOAD_SUCCESS = 'TEAM_LOAD_SUCCESS';
export const TEAM_LOAD_FAILURE = 'TEAM_LOAD_FAILURE';

export const TEAM_LOAD_MONTH_REQUEST = 'TEAM_LOAD_MONTH_REQUEST';
export const TEAM_LOAD_MONTH_SUCCESS = 'TEAM_LOAD_MONTH_SUCCESS';
export const TEAM_LOAD_MONTH_FAILURE = 'TEAM_LOAD_MONTH_FAILURE';

export const PLANNING_LOAD_REQUEST = 'PLANNING_LOAD_REQUEST';
export const PLANNING_LOAD_SUCCESS = 'PLANNING_LOAD_SUCCESS';
export const PLANNING_LOAD_FAILURE = 'PLANNING_LOAD_FAILURE';

export const AFFECTATION_ADD_REQUEST = 'AFFECTATION_ADD_REQUEST';
export const AFFECTATION_ADD_SUCCESS = 'AFFECTATION_ADD_SUCCESS';
export const AFFECTATION_ADD_FAILURE = 'AFFECTATION_ADD_FAILURE';

export const AFFECTATION_EDIT_REQUEST = 'AFFECTATION_EDIT_REQUEST';
export const AFFECTATION_EDIT_SUCCESS = 'AFFECTATION_EDIT_SUCCESS';
export const AFFECTATION_EDIT_FAILURE = 'AFFECTATION_EDIT_FAILURE';

export const AFFECTATION_DELETE_REQUEST = 'AFFECTATION_DELETE_REQUEST';
export const AFFECTATION_DELETE_SUCCESS = 'AFFECTATION_DELETE_SUCCESS';
export const AFFECTATION_DELETE_FAILURE = 'AFFECTATION_DELETE_FAILURE';

export const TASK_ADD_REQUEST = 'TASK_ADD_REQUEST';
export const TASK_ADD_SUCCESS = 'TASK_ADD_SUCCESS';
export const TASK_ADD_FAILURE = 'TASK_ADD_FAILURE';

export const TASK_EDIT_REQUEST = 'TASK_EDIT_REQUEST';
export const TASK_EDIT_SUCCESS = 'TASK_EDIT_SUCCESS';
export const TASK_EDIT_FAILURE = 'TASK_EDIT_FAILURE';

export const TASK_CONVERT_REQUEST = 'TASK_CONVERT_REQUEST';
export const TASK_CONVERT_SUCCESS = 'TASK_CONVERT_SUCCESS';
export const TASK_CONVERT_FAILURE = 'TASK_CONVERT_FAILURE';

export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST';
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS';
export const TASK_DELETE_FAILURE = 'TASK_DELETE_FAILURE';

export const PREVISIONNEL_ADD_REQUEST = 'PREVISIONNEL_ADD_REQUEST';
export const PREVISIONNEL_ADD_SUCCESS = 'PREVISIONNEL_ADD_SUCCESS';
export const PREVISIONNEL_ADD_FAILURE = 'PREVISIONNEL_ADD_FAILURE';

export const PREVISIONNEL_EDIT_REQUEST = 'PREVISIONNEL_EDIT_REQUEST';
export const PREVISIONNEL_EDIT_SUCCESS = 'PREVISIONNEL_EDIT_SUCCESS';
export const PREVISIONNEL_EDIT_FAILURE = 'PREVISIONNEL_EDIT_FAILURE';

export const PREVISIONNEL_DELETE_REQUEST = 'PREVISIONNEL_DELETE_REQUEST';
export const PREVISIONNEL_DELETE_SUCCESS = 'PREVISIONNEL_DELETE_SUCCESS';
export const PREVISIONNEL_DELETE_FAILURE = 'PREVISIONNEL_DELETE_FAILURE';

export const VERSION_ADD_REQUEST = 'VERSION_ADD_REQUEST';
export const VERSION_ADD_SUCCESS = 'VERSION_ADD_SUCCESS';
export const VERSION_ADD_FAILURE = 'VERSION_ADD_FAILURE';

export const VERSION_EDIT_REQUEST = 'VERSION_EDIT_REQUEST';
export const VERSION_EDIT_SUCCESS = 'VERSION_EDIT_SUCCESS';
export const VERSION_EDIT_FAILURE = 'VERSION_EDIT_FAILURE';

export const VERSION_DELETE_REQUEST = 'VERSION_DELETE_REQUEST';
export const VERSION_DELETE_SUCCESS = 'VERSION_DELETE_SUCCESS';
export const VERSION_DELETE_FAILURE = 'VERSION_DELETE_FAILURE';

export const TEST_ADD_REQUEST = 'TEST_ADD_REQUEST';
export const TEST_ADD_SUCCESS = 'TEST_ADD_SUCCESS';
export const TEST_ADD_FAILURE = 'TEST_ADD_FAILURE';

export const TEST_EDIT_REQUEST = 'TEST_EDIT_REQUEST';
export const TEST_EDIT_SUCCESS = 'TEST_EDIT_SUCCESS';
export const TEST_EDIT_FAILURE = 'TEST_EDIT_FAILURE';

export const TEST_DELETE_REQUEST = 'TEST_DELETE_REQUEST';
export const TEST_DELETE_SUCCESS = 'TEST_DELETE_SUCCESS';
export const TEST_DELETE_FAILURE = 'TEST_DELETE_FAILURE';

export const DEMARRAGE_ADD_REQUEST = 'DEMARRAGE_ADD_REQUEST';
export const DEMARRAGE_ADD_SUCCESS = 'DEMARRAGE_ADD_SUCCESS';
export const DEMARRAGE_ADD_FAILURE = 'DEMARRAGE_ADD_FAILURE';

export const DEMARRAGE_EDIT_REQUEST = 'DEMARRAGE_EDIT_REQUEST';
export const DEMARRAGE_EDIT_SUCCESS = 'DEMARRAGE_EDIT_SUCCESS';
export const DEMARRAGE_EDIT_FAILURE = 'DEMARRAGE_EDIT_FAILURE';

export const DEMARRAGE_DELETE_REQUEST = 'DEMARRAGE_DELETE_REQUEST';
export const DEMARRAGE_DELETE_SUCCESS = 'DEMARRAGE_DELETE_SUCCESS';
export const DEMARRAGE_DELETE_FAILURE = 'DEMARRAGE_DELETE_FAILURE';

export const HORAIRES_ADD_REQUEST = 'HORAIRES_ADD_REQUEST';
export const HORAIRES_ADD_SUCCESS = 'HORAIRES_ADD_SUCCESS';
export const HORAIRES_ADD_FAILURE = 'HORAIRES_ADD_FAILURE';

export const HORAIRES_EDIT_REQUEST = 'HORAIRES_EDIT_REQUEST';
export const HORAIRES_EDIT_SUCCESS = 'HORAIRES_EDIT_SUCCESS';
export const HORAIRES_EDIT_FAILURE = 'HORAIRES_EDIT_FAILURE';

export const HORAIRES_DELETE_REQUEST = 'HORAIRES_DELETE_REQUEST';
export const HORAIRES_DELETE_SUCCESS = 'HORAIRES_DELETE_SUCCESS';
export const HORAIRES_DELETE_FAILURE = 'HORAIRES_DELETE_FAILURE';
