/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
import { Segment, Grid, Form, Select, Checkbox } from 'semantic-ui-react';
import { gql } from 'apollo-boost';
import { fetchApollo } from '../../apolloClient';

import DossierTable from './Table/DossierTable';
import TacheTable from './Table/TacheTable';

const Title = styled.h4``;

class DossierAutre extends Component {
  state = {
    height: 185,
    historique: false,
    type: [],
    dossier: [],
    tTache: [],
    tDossier: [],
    tFilteredDossier: [],
    tOptionDossier: []
  };

  resizing = false;

  componentDidMount() {
    this.getDossiers();
  }

  componentDidUpdate(prevProps) {
    const { tDossier } = this.props;
    if (!this.resizing && prevProps.tDossier !== tDossier) {
      this.setState({
        tFilteredDossier: tDossier,
        tDossier,
        tOptionDossier: tDossier
      });
    }
  }

  onSelectDossier = iPKDossier => {
    const { tDossier } = this.props;
    const dossier = tDossier.filter(x => x.iPKDossier === iPKDossier)[0];
    if (dossier && dossier.AssPlanningTasks) {
      this.setState({
        tTache: dossier.AssPlanningTasks.concat(dossier.AssParentPlanningTasks)
      });
    }
  };

  onResize = (event, { size }) => {
    this.resizing = true;
    this.setState({ height: size.height });
  };

  onHandleChange = (event, data) => {
    this.setState({ [data.id]: data.value }, () => this.loadDossier());
  };

  onHistoryChange = () => {
    const { historique } = this.state;
    this.setState({ historique: !historique }, () => this.getDossiers());
  };

  getDossiers() {
    const { iPKClient } = this.props;
    const { historique } = this.state;
    if (historique) {
      const GET_DOSSIERS = gql`
        {
          getDossiers(iPKClient: ${iPKClient}, fullList: true) {
            iPKDossier
            iFKModule
            iFKClient
            iFKAffaire
            sFKIntervenant
            sConcerne
            sDescription
            sEtat
            iFKDossierMaitre
            AssNatureDossier {
              sPKNature
              sLibelle
            }
            AssEtatDossier {
              sLibelle
              iPKDossierEtat
            }
            AssPlanningTasks {
              iPKTask
              sFKIntervenant
              dDebut
              dFin
              AssPlanningTaskType {
                sLibelle
              }
            }
            bReglementaire
            bLourd
            bSpecifique
            bUrgent
            bPrive
          }
        }
      `;

      fetchApollo(GET_DOSSIERS).then(response => {
        this.setState({
          tFilteredDossier: response.data.getDossiers,
          tDossier: response.data.getDossiers,
          tOptionDossier: response.data.getDossiers,
          type: [],
          dossier: []
        });
      });
    } else {
      const { tDossier } = this.props;
      this.setState({
        tDossier,
        tFilteredDossier: tDossier,
        tOptionDossier: tDossier,
        type: [],
        dossier: []
      });
    }
  }

  loadDossier = () => {
    const { type, dossier, tDossier } = this.state;
    // const { tDossier } = this.props;
    if (type.length > 0 && dossier.length === 0) {
      this.setState({
        tFilteredDossier: tDossier.filter(
          x => x.AssNatureDossier && type.includes(x.AssNatureDossier.sLibelle)
        ),
        tOptionDossier: tDossier.filter(
          x => x.AssNatureDossier && type.includes(x.AssNatureDossier.sLibelle)
        )
      });
    } else if (dossier.length > 0 && type.length === 0) {
      this.setState({
        tFilteredDossier: tDossier.filter(x => dossier.includes(x.iPKDossier)),
        tOptionDossier: tDossier
      });
    } else if (type.length > 0 && dossier.length > 0) {
      this.setState({
        tFilteredDossier: tDossier.filter(
          x =>
            x.AssNatureDossier &&
            type.includes(x.AssNatureDossier.sLibelle) &&
            dossier.includes(x.iPKDossier)
        ),
        tOptionDossier: tDossier.filter(
          x => x.AssNatureDossier && type.includes(x.AssNatureDossier.sLibelle)
        )
      });
    } else {
      this.setState({
        tFilteredDossier: tDossier,
        tOptionDossier: tDossier
      });
    }
  };

  renderLabel = label => ({
    content: label.value
  });

  render() {
    const { height, tTache, tFilteredDossier, tOptionDossier, type, dossier } = this.state;
    const { tNatureDossier } = this.props;
    return (
      <>
        <Title>Autres Dossiers ({tFilteredDossier.length})</Title>
        <Segment>
          <Resizable
            className="box"
            width={600}
            height={height}
            onResize={this.onResize}
            resizeHandles={['s']}
          >
            <Grid columns={2}>
              <Grid.Column width={13}>
                <Form>
                  <Form.Group>
                    <Form.Field
                      id="type"
                      control={Select}
                      className="tiny"
                      style={{ padding: 0 }}
                      options={tNatureDossier.map(natureDossier => {
                        return {
                          key: natureDossier.sLibelle,
                          value: natureDossier.sLibelle,
                          text: natureDossier.sLibelle
                        };
                      })}
                      multiple
                      clearable
                      value={type}
                      placeholder="Nature"
                      onChange={this.onHandleChange}
                      search
                      searchInput={{ id: 'type' }}
                    />
                    <Form.Field
                      id="dossier"
                      control={Select}
                      width={8}
                      className="tiny"
                      style={{ padding: '2px 0px 0 4px' }}
                      options={tOptionDossier.map(item => {
                        return {
                          key: item.iPKDossier,
                          value: item.iPKDossier,
                          text: `${item.iPKDossier} - ${item.sConcerne}`
                        };
                      })}
                      renderLabel={this.renderLabel}
                      multiple
                      clearable
                      value={dossier}
                      placeholder="Dossier"
                      onChange={this.onHandleChange}
                      search
                      searchInput={{ id: 'dossier' }}
                    />
                    <Form.Field
                      id="historique"
                      control={Checkbox}
                      className="tiny"
                      data-tooltip="Affiche tous les dossiers."
                      style={{ padding: '4px 0px 0 4px', marginTop: 5 }}
                      label={<label> Accès à tous les dossiers</label>}
                      onChange={this.onHistoryChange}
                    />
                  </Form.Group>
                </Form>

                <DossierTable
                  tDossier={tFilteredDossier}
                  onSelectDossier={this.onSelectDossier}
                  maxHeight={`${height}px`}
                />
              </Grid.Column>
              <Grid.Column style={{ marginTop: 48 }} width={3}>
                <TacheTable tTache={tTache} maxHeight={`${height}px`} />
              </Grid.Column>
            </Grid>
          </Resizable>
        </Segment>
      </>
    );
  }
}

DossierAutre.defaultProps = {
  tDossier: null
};

DossierAutre.propTypes = {
  iPKClient: PropTypes.number.isRequired,
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tNatureDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  tNatureDossier: state.config.tNatureDossier
});

export default connect(mapStateToProps)(DossierAutre);
