import React from 'react';
import PropTypes from 'prop-types';

import { CompactCell, CellInput } from '../TableStyle';

const CelliPriorite = ({ user, size, iPriorite, iPKDossier, handleChangePriority }) => {
  return (
    <CompactCell style={{ textAlign: 'center' }}>
      {user.iFKClient === 99 ? (
        <CellInput
          style={{
            position: 'relative',
            left: size !== 's' ? 7 : 0,
            overflow: 'hidden'
          }}
          value={iPriorite || 0}
          onChange={e => handleChangePriority(e, iPKDossier)}
        />
      ) : (
        iPriorite || 0
      )}
    </CompactCell>
  );
};

CelliPriorite.defaultProps = {
  iPriorite: null
};

CelliPriorite.propTypes = {
  size: PropTypes.string.isRequired,
  iPriorite: PropTypes.number,
  iPKDossier: PropTypes.number.isRequired,
  handleChangePriority: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CelliPriorite;
