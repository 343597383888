import { combineReducers } from 'redux';

import { USER_SIGNOUT_SUCCESS } from './constants/ActionTypes';

import config from './reducers/config';
import loadings from './reducers/loadings';
import user from './reducers/user';
import network from './reducers/network';

const appReducer = combineReducers({
  config,
  loadings,
  network,
  user
});

export default (state, action) => {
  let currentState = state;

  if (action.type === USER_SIGNOUT_SUCCESS) {
    currentState = undefined;
  }

  return appReducer(currentState, action);
};
