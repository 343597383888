import {
  CONFIG_LOAD_REQUEST,
  CONFIG_GET_DOSSIERS_REQUEST,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST,
  CONFIG_GET_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST,
  SET_DOSSIER_REQUEST,
  SET_PRIORITE_DOSSIER_REQUEST
} from '../constants/ActionTypes';

export const loadConfig = () => ({
  type: CONFIG_LOAD_REQUEST,
  payload: {}
});

export const getDossiers = (iPKClient, fullList) => ({
  type: CONFIG_GET_DOSSIERS_REQUEST,
  payload: { iPKClient, fullList }
});

export const getDossiersReglementaire = () => ({
  type: CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST,
  payload: null
});

export const getCategorieDossier = iPKClient => ({
  type: CONFIG_GET_CATEGORIE_DOSSIER_REQUEST,
  payload: { iPKClient }
});

export const addCategorieDossier = (iFKClient, sLibelle) => ({
  type: CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST,
  payload: { iFKClient, sLibelle }
});

export const editCategorieDossier = (iPKDossierCategorie, sLibelle) => ({
  type: CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST,
  payload: { iPKDossierCategorie, sLibelle }
});

export const deleteCategorieDossier = iPKDossierCategorie => ({
  type: CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST,
  payload: { iPKDossierCategorie }
});

export const setDossierImportant = (iPKDossier, bUrgent) => ({
  type: SET_DOSSIER_REQUEST,
  payload: { iPKDossier, bUrgent }
});

export const setPrioriteDossier = (iPKDossier, iPriorite) => ({
  type: SET_PRIORITE_DOSSIER_REQUEST,
  payload: { iPKDossier, iPriorite }
});
