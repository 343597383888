import React from 'react';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { CellContent, CompactCell } from '../TableStyle';

const CellsFKIntervenant = ({ sFKIntervenant }) => {
  const currentDate = DateTime.local();
  return (
    <CompactCell>
      {' '}
      <Popup
        content="Ouvre le planning de l'intervenant"
        position="top right"
        trigger={
          <CellContent style={{ position: 'relative', left: 5 }} centered="true">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/equipe/planning/${sFKIntervenant}/${currentDate.toFormat('yyyy-LL-dd')}`}
            >
              {sFKIntervenant}
            </a>
          </CellContent>
        }
      />
    </CompactCell>
  );
};

CellsFKIntervenant.propTypes = {
  sFKIntervenant: PropTypes.string.isRequired
};

export default CellsFKIntervenant;
