import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import jwt from 'jsonwebtoken';

import { setAuthorizationToken } from '../utils';
import { fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS
} from '../constants/ActionTypes';

export function* signUserIn({ payload: { sLogin, sPassword } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'login' } });

  if (!navigator.onLine) {
    alert('TODO: USER_SIGNIN_REQUEST Offline');
  } else {
    try {
      const AUTORISE = gql`{
        autoriseUtilisateur(sLogin: "${sLogin}", sPassword: "${sPassword}") {
          sLogin
          iFKClient
          iRole
        }
      }`;
      const response = yield call(fetchApollo, AUTORISE);
      const user = response.data.autoriseUtilisateur;
      if (user !== null) {
        user.sLogin = sLogin;
        user.sPassword = sPassword;
        user.dDate = new Date();

        const token = jwt.sign(user, process.env.REACT_APP_JWT_SECRET, {
          expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN
        });

        setAuthorizationToken(token);
        localStorage.setItem('jwtToken', JSON.stringify(token));

        yield put({ type: USER_SIGNIN_SUCCESS, payload: user });
      } else {
        localStorage.removeItem('jwtToken');

        yield put({ type: USER_SIGNIN_FAILURE, payload: 'Identifiants incorrects' });
        yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'login' } });
      }
    } catch (error) {
      let message;

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Identifiants incorrects';
          break;
        default:
          message = error.message;
      }

      localStorage.removeItem('jwtToken');
      yield put({ type: USER_SIGNIN_FAILURE, payload: message });
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'login' } });
    }
  }
}

export function* signUserOut() {
  //if (localStorage.getItem('jwtToken')) {
  yield put({
    type: USER_SIGNOUT_SUCCESS,
    payload: {}
  });
  //} else {
  // client.resetStore();
  // yield put({
  //   type: USER_SIGNOUT_FAILURE,
  //   payload: {}
  // });
  //}
}
