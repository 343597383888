import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CellContent } from '../TableStyle';

const CellsEtat = ({ sEtat }) => {
  return (
    <Table.Cell width={2}>
      <CellContent style={{ position: 'relative', left: 3 }}>{sEtat}</CellContent>
    </Table.Cell>
  );
};

CellsEtat.propTypes = {
  sEtat: PropTypes.string.isRequired
};

export default CellsEtat;
