import React from 'react';
import PropTypes from 'prop-types';

import { CellContent, CompactCell } from '../TableStyle';

const CellsNature = ({ sNature }) => {
  return (
    <CompactCell>
      <CellContent centered="true">{sNature}</CellContent>
    </CompactCell>
  );
};

CellsNature.propTypes = {
  sNature: PropTypes.string.isRequired
};

export default CellsNature;
