const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  colors: {
    // Couleurs du logo
    blueAGIR: process.env.REACT_APP_TEST === 'true' ? 'orange' : 'rgb(0, 151, 174)',
    blueBASELINE: 'rgb(12, 102, 124)',
    greyADOM: 'rgb(88, 88, 88)',

    blue: process.env.REACT_APP_TEST === 'true' ? 'orange' : 'rgb(57, 178, 198)',
    orange: '#ff832f', // 'rgb(248, 179, 52)',
    red: 'red',
    purple: 'rgb(161, 13, 89)',
    purpleBackgroundLighter: 'rgba(161, 13, 89, 0.1)',
    purpleBackgroundLight: 'rgba(161, 13, 89, 0.25)',
    purpleDark: 'rgb(141, 0, 69)',
    blueDark: process.env.REACT_APP_TEST === 'true' ? 'orange' : 'rgb(0, 151, 174)',
    green: 'green',
    greenLight: 'rgb(223, 219, 0)',
    greenDark: 'rgb(62, 167, 28)',
    yellow: 'rgb(252, 234, 13)',
    white: 'rgb(255, 255, 255)',
    grey: 'rgb(214, 215, 216)',
    textBlack: 'rgb(88, 88, 88)',

    backgroundLight: 'rgb(241, 242, 246)',
    backgroundLighter: 'rgb(251, 252, 255)',
    borderLight: 'rgba(34,36,38,.15)'
  }
};

export default theme;
