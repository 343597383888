/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { Table, Popup } from 'semantic-ui-react';

class TacheTable extends Component {
  render() {
    const { tTache, maxHeight, user, top } = this.props;
    const tTacheSansDoublon = _.uniqWith(tTache, (a, b) => {
      return (
        a.AssPlanningTaskType.sLibelle === b.AssPlanningTaskType.sLibelle &&
        DateTime.fromMillis(parseInt(a.dDebut, 10)).weekNumber ===
          DateTime.fromMillis(parseInt(b.dDebut, 10)).weekNumber
      );
    });
    const currentDate = DateTime.local();

    return (
      <div style={{ margin: `${top}px 0 0` }}>
        <Table style={{ height: maxHeight, display: 'grid' }} sortable compact size="small">
          <Table.Body style={{ maxHeight }}>
            {tTacheSansDoublon.length > 0 ? (
              _.map(
                tTacheSansDoublon,
                ({ iPKTask, sFKIntervenant, AssPlanningTaskType, dDebut, dFin }) => (
                  <Table.Row
                    style={{
                      color: currentDate.toMillis() > parseInt(dDebut, 10) ? 'grey' : 'black'
                    }}
                    textAlign="center"
                    key={iPKTask}
                  >
                    {user.iFKClient === 99 && <Table.Cell width={2}>{sFKIntervenant}</Table.Cell>}
                    <Table.Cell width={3}>
                      {user.iFKClient === 99 ? (
                        <Popup
                          position="top center"
                          content={
                            <div>
                              {DateTime.fromMillis(parseInt(dDebut, 10)).toFormat('HH:mm')} -{' '}
                              {DateTime.fromMillis(parseInt(dFin, 10)).toFormat('HH:mm')}
                            </div>
                          }
                          trigger={
                            <div>
                              {DateTime.fromMillis(parseInt(dDebut, 10)).toFormat('dd.LL.yy')}
                            </div>
                          }
                        />
                      ) : (
                        `S ${DateTime.fromMillis(parseInt(dDebut, 10)).weekNumber}`
                      )}
                    </Table.Cell>
                    <Table.Cell width={11} style={{ lineHeight: '1em' }}>
                      {AssPlanningTaskType.sLibelle === 'Gestion des dossiers / Planification'
                        ? AssPlanningTaskType.sLibelle.replace('/', '\n')
                        : AssPlanningTaskType.sLibelle}
                    </Table.Cell>
                  </Table.Row>
                )
              )
            ) : (
              <Table.Row textAlign="center">
                <Table.Cell width={16}>
                  <div style={{ padding: 20 }}>Aucun résultat</div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

TacheTable.defaultProps = {
  top: 0
};

TacheTable.propTypes = {
  tTache: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  maxHeight: PropTypes.string.isRequired,
  top: PropTypes.number
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default withRouter(connect(mapStateToProps)(TacheTable));
