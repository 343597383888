/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Resizable } from 'react-resizable';
import { Segment, Grid, Form, Select } from 'semantic-ui-react';

import { gql } from 'apollo-boost';
import { fetchApollo } from '../../apolloClient';

import DossierTable from './Table/DossierTable';

const Title = styled.h4`
  margin-top: 10px;
`;

const SemaineLivraison = styled.span`
  margin-left: 5px;
`;

class DossierProchaineVersion extends Component {
  constructor(props) {
    super(props);

    const { syncHeight } = this.props;

    this.state = {
      client: [],
      tDossier: [],
      syncHeight
    };
  }

  componentDidMount() {
    const { iPKClient } = this.props;

    if (iPKClient) {
      this.setState({ client: [iPKClient] }, () => {
        this.getDossiers();
      });
    } else {
      this.getDossiers();
    }
  }

  componentDidUpdate(prevProps) {
    const { syncHeight } = this.props;

    if (syncHeight !== prevProps.syncHeight) {
      this.setState({ syncHeight });
    }
  }

  getDossiers() {
    const { client } = this.state;

    const GET_DOSSIER = gql`
      {
        getDossiersPlanifies ${client.length > 0 ? `(client: "${JSON.stringify(client)}")` : ''} {
          iPKDossier
          iFKModule
          iFKClient
          iFKAffaire
          sFKIntervenant
          sConcerne
          sDescription
          sVersionPrevisionnelle
          sLivraison
          AssNatureDossier {
            sLibelle
            sPKNature
          }
          bReglementaire
          bLourd
          bSpecifique
          bUrgent
          bPrive
          iFKDossierMaitre
        }
      }
    `;

    fetchApollo(GET_DOSSIER).then(response => {
      const tPreviDossier = [];
      response.data.getDossiersPlanifies.forEach(dossier => {
        if (!tPreviDossier[dossier.sVersionPrevisionnelle]) {
          tPreviDossier[dossier.sVersionPrevisionnelle] = [];
        }
        tPreviDossier[dossier.sVersionPrevisionnelle].push(dossier);
      });
      this.setState({
        // loading: false,
        client,
        tDossier: tPreviDossier
      });
    });
  }

  onHandleChange = (e, data) => {
    this.setState({ [data.id]: data.value }, () => this.getDossiers());
  };

  onSelectDossier = () => {};

  onResize = (event, { size }) => {
    const nextVersion = window.document.getElementById('content-next-version');
    if (size.height > nextVersion.clientHeight - 111) {
      this.setState({ syncHeight: size.height });
    }
  };

  onResizeStop = (event, { size }) => {
    const { handleSyncHeight } = this.props;
    const nextVersion = window.document.getElementById('content-next-version');
    if (nextVersion.clientHeight > size.height) {
      handleSyncHeight(nextVersion.clientHeight - 100);
    }
  };

  render() {
    const { syncHeight, client, tDossier } = this.state;
    const { tClient, user } = this.props;

    return (
      <>
        <Title>Dossiers des prochaines versions</Title>

        <Resizable
          className="box"
          width={600}
          height={syncHeight}
          onResize={this.onResize}
          onResizeStop={this.onResizeStop}
          resizeHandles={['s']}
        >
          <Segment style={{ minHeight: syncHeight + 111 < 200 ? 200 : syncHeight + 111 }}>
            <Grid columns={1}>
              <Grid.Column id="content-next-version" width={16}>
                {user.iFKClient === 99 && (
                  <Form>
                    <Form.Group>
                      <Form.Field
                        inline
                        id="client"
                        control={Select}
                        className="tiny"
                        size="mini"
                        style={{ padding: '2px 0px 0 4px' }}
                        options={tClient.map(item => {
                          return {
                            key: item.iPKClient,
                            value: item.iPKClient,
                            text: item.sNomAbrege
                          };
                        })}
                        placeholder="Client"
                        search
                        multiple
                        clearable
                        value={client}
                        onChange={this.onHandleChange}
                        label={<label htmlFor="client">Client</label>}
                        searchInput={{ id: 'client' }}
                      />
                    </Form.Group>
                  </Form>
                )}

                {[...Array(6).keys()].map(i => {
                  return (
                    <div style={{ marginTop: 10 }} key={`containDossierProchaineVersion-${i}`}>
                      {tDossier[Object.keys(tDossier)[i]] && (
                        <>
                          Version {Object.keys(tDossier)[i]}
                          {user.iFKClient === 99 &&
                            tDossier[Object.keys(tDossier)[i]][0].sLivraison && (
                              <SemaineLivraison>
                                Semaine {tDossier[Object.keys(tDossier)[i]][0].sLivraison}
                              </SemaineLivraison>
                            )}
                          <DossierTable
                            key={`dossierProchaineVersion-${i}`}
                            tDossier={tDossier[Object.keys(tDossier)[i]]}
                            onSelectDossier={this.onSelectDossier}
                            maxHeight="200px"
                            size="s"
                          />
                        </>
                      )}
                    </div>
                  );
                })}
              </Grid.Column>
            </Grid>
          </Segment>
        </Resizable>
      </>
    );
  }
}

DossierProchaineVersion.propTypes = {
  iPKClient: PropTypes.number.isRequired,
  tClient: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  syncHeight: PropTypes.number.isRequired,
  handleSyncHeight: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  tClient: state.config.tClient
});

export default connect(mapStateToProps)(DossierProchaineVersion);
