import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { Container, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { signUserOut } from '../../../../actions/user';

import logoADS from '../../../../assets/img/ads.png';

const MyContainer = styled(Container)`
  padding: 0 0 0 110px;
  height: 40px;
  width: 90% !important;
  background-image: url(${logoADS});
  background-size: 92px 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 40px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 2px 0 rgba(92, 92, 94, 0.25);
`;

const User = styled.div`
  float: right;
`;

const UserName = styled.div`
  float: left;
  margin: 5px 80px 5px 0;
  height: 30px;
  line-height: 30px;
  color: ${props => props.theme.colors.textBlack};
`;

const UserAction = styled.div`
  float: right;
  display: block;
  margin: 0 20px 0;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
`;

const MainNav = styled.ul`
  margin: 0;
  padding: 0;
`;
const NavItem = styled.li`
  display: inline-block;
  list-style: none;
`;
const NavItemLink = styled(NavLink)`
  display: block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  font-family: 'Futura';
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.colors.blue};
  background: ${props => props.theme.colors.white};
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blue};
  }

  &.active {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
    border-right: 1px solid ${props => props.theme.colors.blueDark};
  }
`;

const BtnUserSignOut = styled.button`
  width: 40px;
  height: 40px;
  color: ${props => props.theme.colors.blueDark};
  background: ${props => props.theme.colors.white};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

class TopBar extends Component {
  signUserOut = () => {
    const { dispatch } = this.props;
    localStorage.removeItem('jwtToken');
    dispatch(signUserOut());
  };

  render() {
    const { user, isAuthenticated } = this.props;

    return (
      <Wrapper>
        <MyContainer>
          <User>
            {isAuthenticated ? (
              <>
                <UserAction>
                  <BtnUserSignOut onClick={this.signUserOut}>
                    <Icon name="power off" size="large" />
                  </BtnUserSignOut>
                </UserAction>
                <UserName>{`${user.sLogin}`}</UserName>
              </>
            ) : (
              <>
                <UserName>{`${user.sLogin}`}</UserName>
              </>
            )}
          </User>
          <MainNav>
            {user.iRole === 1 && (
              <NavItem>
                <NavItemLink to="/clients">Clients</NavItemLink>
              </NavItem>
            )}
            <NavItem>
              <NavItemLink to="/client">Suivi dossiers</NavItemLink>
            </NavItem>
            <NavItem style={{ float: 'right', width: 51, marginRight: 10 }}>
              <NavItemLink style={{ padding: '0 10px' }} to="/parametres">
                <Icon name="cog" size="big" />
              </NavItemLink>
            </NavItem>
          </MainNav>
        </MyContainer>
      </Wrapper>
    );
  }
}

TopBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.data,
  signOutError: state.user.error
});

export default connect(mapStateToProps)(TopBar);
