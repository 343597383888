/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Table } from 'semantic-ui-react';

import { setDossierImportant, setPrioriteDossier } from '../../../actions/config';

import DossierTableHead from './DossierTableHeader';
import DossierTableRow from './DossierTableRow';

import Cells from './Cells';
import { TableBody, CellContent } from './TableStyle';

class DossierTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tStateDossier: [],
      column: null,
      direction: null,
      selected: null
    };
  }

  componentDidMount() {
    const { tStateDossier } = this.state;
    const { tDossier, size } = this.props;

    if (tDossier !== tStateDossier) {
      const returnDossier = [];
      tDossier.forEach(dossier => {
        returnDossier.push({
          iPKDossier: dossier.iPKDossier,
          iFKClient: dossier.iFKClient,
          iFKDossierMaitre: dossier.iFKDossierMaitre,
          iPKNature: dossier.AssNatureDossier && dossier.AssNatureDossier.iPKDossierNature,
          sNature: dossier.AssNatureDossier && dossier.AssNatureDossier.sPKNature,
          sConcerne: dossier.sConcerne,
          iPKEtat: dossier.AssEtatDossier && dossier.AssEtatDossier.iPKDossierEtat,
          sEtat: dossier.AssEtatDossier && dossier.AssEtatDossier.sLibelle,
          sFKIntervenant: dossier.sFKIntervenant,
          bUrgent: dossier.bUrgent,
          bReglementaire: dossier.bReglementaire,
          bPrioritaire: dossier.bPrioritaire,
          bSpecifique: dossier.bSpecifique,
          bLourd: dossier.bLourd,
          bPrive: dossier.bPrive,
          iPriorite: dossier.iPriorite
        });
      });
      this.setState(
        {
          tStateDossier: _.sortBy(returnDossier, 'iPKEtat'),
          column: 'iPKEtat',
          direction: 'ascending'
        },
        () => {
          const { tStateDossier } = this.state;
          if (size !== 's' && tStateDossier.length > 0) {
            this.onSelectDossier(tStateDossier[0].iPKDossier);
          }
        }
      );
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { tDossier, size } = this.props;
    const { column, direction } = this.state;
    if (tDossier !== prevProps.tDossier) {
      const returnDossier = [];
      tDossier.forEach(dossier => {
        returnDossier.push({
          iPKDossier: dossier.iPKDossier,
          iFKClient: dossier.iFKClient,
          iFKDossierMaitre: dossier.iFKDossierMaitre,
          iPKNature: dossier.AssNatureDossier && dossier.AssNatureDossier.iPKDossierNature,
          sNature: dossier.AssNatureDossier && dossier.AssNatureDossier.sPKNature,
          sConcerne: dossier.sConcerne,
          iPKEtat: dossier.AssEtatDossier && dossier.AssEtatDossier.iPKDossierEtat,
          sEtat: dossier.AssEtatDossier && dossier.AssEtatDossier.sLibelle,
          sFKIntervenant: dossier.sFKIntervenant,
          bUrgent: dossier.bUrgent,
          bReglementaire: dossier.bReglementaire,
          bSpecifique: dossier.bSpecifique,
          bPrioritaire: dossier.bPrioritaire,
          bLourd: dossier.bLourd,
          bPrive: dossier.bPrive,
          iPriorite: dossier.iPriorite
        });
      });
      if (column) {
        this.setState(
          {
            tStateDossier:
              direction === 'ascending'
                ? _.sortBy(returnDossier, [column])
                : _.sortBy(returnDossier, [column]).reverse()
          },
          () => {
            size !== 's' && returnDossier[0] && this.onSelectDossier(returnDossier[0].iPKDossier);
          }
        );
      } else {
        this.setState(
          {
            tStateDossier: returnDossier
          },
          () => {
            size !== 's' && returnDossier[0] && this.onSelectDossier(returnDossier[0].iPKDossier);
          }
        );
      }
    }
  }

  handleSort = clickedColumn => () => {
    const { column, tStateDossier, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tStateDossier: _.sortBy(tStateDossier, [clickedColumn]),
        direction: 'ascending'
      });

      return;
    }

    this.setState({
      tStateDossier: tStateDossier.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    });
  };

  onSelectDossier = iPKDossier => {
    const { onSelectDossier } = this.props;
    this.setState(
      {
        selected: iPKDossier
      },
      () => onSelectDossier(iPKDossier)
    );
  };

  handleChangePriority = (e, iPKDossier) => {
    const { dispatch } = this.props;
    const { tStateDossier } = this.state;
    const oDossier = tStateDossier.filter(d => d.iPKDossier === iPKDossier)[0];
    oDossier.iPriorite = e.target.value;

    this.setState({ tStateDossier }, () => {
      dispatch(setPrioriteDossier(iPKDossier, oDossier.iPriorite));
    });
  };

  handleChange = (iPKDossier, checked) => {
    const { dispatch } = this.props;
    dispatch(setDossierImportant(iPKDossier, !checked));
  };

  render() {
    const { column, direction, selected, tStateDossier } = this.state;
    const { maxHeight, size, user } = this.props;
    return (
      <Table style={{ display: 'grid' }} sortable selectable compact="very" size="small">
        <DossierTableHead
          size={size}
          user={user}
          column={column}
          direction={direction}
          sort={this.handleSort}
        />
        <TableBody dossierlength={tStateDossier.length} maxheight={maxHeight}>
          {tStateDossier.length > 0 ? (
            _.map(
              tStateDossier,
              ({
                iPKDossier,
                iFKClient,
                iFKDossierMaitre,
                sNature,
                sConcerne,
                iPKEtat,
                sEtat,
                sFKIntervenant,
                bUrgent,
                bReglementaire,
                bSpecifique,
                bLourd,
                bPrive,
                iPriorite,
                bPrioritaire
              }) => {
                return (
                  <DossierTableRow
                    onSelectDossier={this.onSelectDossier}
                    size={size}
                    bLourd={bLourd}
                    iPKEtat={iPKEtat}
                    iPKDossier={iPKDossier}
                    selected={selected}
                    key={iPKDossier}
                  >
                    <Cells.CelliPKDossier
                      size={size}
                      iPKDossier={iPKDossier}
                      iFKClient={iFKClient}
                      currentClient={user.iFKClient}
                      bPrive={bPrive}
                      iFKDossierMaitre={iFKDossierMaitre}
                    />
                    {size === 'l' && (
                      <>
                        <Cells.CellbUrgent
                          user={user}
                          iPKDossier={iPKDossier}
                          bUrgent={bUrgent}
                          handleChange={this.handleSort}
                        />
                        <Cells.CellbCell
                          boolean={bReglementaire}
                          popupValue="réglementaire"
                          value="R"
                        />
                        <Cells.CellbCell boolean={bSpecifique} popupValue="spécifique" value="S" />
                        <Cells.CellbCell
                          boolean={bPrioritaire}
                          popupValue="prioritaire"
                          value="P"
                        />
                      </>
                    )}
                    {size === 'l' || size === 's' ? (
                      <>
                        <Cells.CellsNature sNature={sNature} />
                        {size === 's' ? (
                          <Cells.CellRecapStatus
                            bReglementaire={bReglementaire}
                            bSpecifique={bSpecifique}
                            bPrioritaire={bPrioritaire}
                          />
                        ) : null}
                      </>
                    ) : null}
                    <Cells.CellsConcerne sConcerne={sConcerne} />
                    {size !== 's' ? (
                      <Cells.CellsEtat sEtat={sEtat && sEtat[0].toUpperCase() + sEtat.slice(1)} />
                    ) : null}
                    {user.iFKClient === 99 && (
                      <Cells.CellsFKIntervenant sFKIntervenant={sFKIntervenant} />
                    )}
                    {size !== 's' && (
                      <Cells.CelliPriorite
                        user={user}
                        size={size}
                        iPriorite={iPriorite}
                        iPKDossier={iPKDossier}
                        handleChangePriority={this.handleChangePriority}
                      />
                    )}
                  </DossierTableRow>
                );
              }
            )
          ) : (
            <Table.Row>
              <Table.Cell width={16}>
                <CellContent centered="true">Aucun résultat</CellContent>
              </Table.Cell>
            </Table.Row>
          )}
        </TableBody>
      </Table>
    );
  }
}

DossierTable.defaultProps = {
  size: 'l'
};

DossierTable.propTypes = {
  onSelectDossier: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  maxHeight: PropTypes.string.isRequired,
  size: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(DossierTable);
