import { LOADING_ACTIVATE_SUCCESS, LOADING_DEACTIVATE_SUCCESS } from '../constants/ActionTypes';

const initialLoadingState = {
  loaders: {
    connexion: true
  }
};

export default (state = initialLoadingState, { type, payload }) => {
  switch (type) {
    case LOADING_ACTIVATE_SUCCESS: {
      const loaders = Object.assign({}, state.loaders, { [payload.target]: true });

      return { loaders };
    }

    case LOADING_DEACTIVATE_SUCCESS: {
      const loaders = Object.assign({}, state.loaders, { [payload.target]: false });

      return { loaders };
    }

    default:
      return state;
  }
};
