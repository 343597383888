/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
import { Segment, Grid } from 'semantic-ui-react';

import DossierTable from './Table/DossierTable';
import TacheTable from './Table/TacheTable';

const Title = styled.h4`
  margin-top: 10px;
`;

class DossierRegementaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tTache: [],
      height: 174
    };
  }

  onSelectDossier = iPKDossier => {
    const { tDossier } = this.props;
    const dossier = tDossier.filter(x => x.iPKDossier === iPKDossier)[0];
    if (dossier && dossier.AssPlanningTasks) {
      this.setState({
        tTache: dossier.AssPlanningTasks.concat(dossier.AssParentPlanningTasks)
      });
    }
  };

  onResize = (event, { size }) => {
    this.setState({ height: size.height });
  };

  render() {
    const { tTache, height } = this.state;
    const { tDossier } = this.props;
    return (
      <>
        <Title>Dossiers Réglementaires (tous clients) ({tDossier.length})</Title>
        <Segment>
          <Resizable
            className="box"
            width={600}
            height={height}
            onResize={this.onResize}
            resizeHandles={['s']}
          >
            <Grid columns={2}>
              <Grid.Column width={12}>
                <DossierTable
                  tDossier={tDossier}
                  size="m"
                  onSelectDossier={this.onSelectDossier}
                  maxHeight={`${height}px`}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <TacheTable tTache={tTache} maxHeight={`${height}px`} />
              </Grid.Column>
            </Grid>
          </Resizable>
        </Segment>
      </>
    );
  }
}

DossierRegementaire.propTypes = {
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(DossierRegementaire);
