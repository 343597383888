import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBtn = styled.button`
  display: inline-block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const Btn = ({ children, onClick, className }) => {
  return (
    <StyledBtn className={className} onClick={onClick}>
      {children}
    </StyledBtn>
  );
};

Btn.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ]).isRequired
};

export default Btn;
