import {
  CONFIG_LOAD_SUCCESS,
  CONFIG_LOAD_FAILURE,
  CONFIG_GET_DOSSIERS_SUCCESS,
  CONFIG_GET_DOSSIERS_FAILURE,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE,
  CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_GET_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE,
  SET_DOSSIER_SUCCESS,
  SET_DOSSIER_FAILURE
} from '../constants/ActionTypes';

const initialconfigState = {
  tClient: [],
  tDossier: [],
  tPrevisionnel: [],
  tNatureDossier: [],
  error: null
};

export default (state = initialconfigState, { type, payload }) => {
  switch (type) {
    case CONFIG_LOAD_SUCCESS: {
      return {
        ...state,
        tClient: payload.tClient,
        tNatureDossier: payload.tNatureDossier,
        error: null
      };
    }
    case CONFIG_LOAD_FAILURE: {
      return { ...state, error: payload };
    }

    case CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS: {
      return {
        ...state,
        tCategorieDossier: payload.tCategorieDossier,
        error: null
      };
    }
    case CONFIG_GET_CATEGORIE_DOSSIER_FAILURE: {
      return { ...state, error: payload };
    }
    case CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS: {
      const { tCategorieDossier } = state;
      return {
        ...state,
        tCategorieDossier: [...tCategorieDossier, payload.categorieDossier],
        error: null
      };
    }
    case CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE: {
      return { ...state, error: payload };
    }
    case CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS: {
      const { tCategorieDossier } = state;
      return {
        ...state,
        tCategorieDossier: tCategorieDossier.map(cd =>
          cd.iPKDossierCategorie === payload.categorieDossier.iPKDossierCategorie
            ? payload.categorieDossier
            : cd
        ),
        error: null
      };
    }
    case CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE: {
      return { ...state, error: payload };
    }
    case CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS: {
      const { tCategorieDossier } = state;
      return {
        ...state,
        tCategorieDossier: tCategorieDossier.filter(
          cd => cd.iPKDossierCategorie !== payload.categorieDossier.iPKDossierCategorie
        ),
        error: null
      };
    }
    case CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE: {
      return { ...state, error: payload };
    }

    case CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS: {
      return {
        ...state,
        tDossierReglementaire: payload.tDossier,
        error: null
      };
    }
    case CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE: {
      return { ...state, error: payload };
    }

    case CONFIG_GET_DOSSIERS_SUCCESS: {
      return {
        ...state,
        tDossier: payload.tDossier,
        error: null
      };
    }
    case CONFIG_GET_DOSSIERS_FAILURE: {
      return { ...state, error: payload };
    }

    case SET_DOSSIER_SUCCESS: {
      const { tDossier } = state;
      const newtDossier = tDossier.filter(x => x.iPKDossier !== payload.dossier.iPKDossier);
      newtDossier.unshift(payload.dossier);
      return {
        ...state,
        tDossier: newtDossier,
        error: null
      };
    }
    case SET_DOSSIER_FAILURE: {
      return { ...state, error: payload };
    }

    default:
      return state;
  }
};
