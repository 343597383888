import CellbUrgent from './bUrgent';
import CelliPKDossier from './iPKDossier';
import CellRecapStatus from './recapStatus';
import CelliPriorite from './iPriorite';
import CellsFKIntervenant from './sFKIntervenant';
import CellbCell from './bCell';
import CellsConcerne from './sConcerne';
import CellsNature from './sNature';
import CellsEtat from './sEtat';

export default {
  CellbUrgent,
  CelliPKDossier,
  CellRecapStatus,
  CelliPriorite,
  CellsConcerne,
  CellsFKIntervenant,
  CellsNature,
  CellsEtat,
  CellbCell
};
