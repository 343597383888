import React from 'react';
import { Table, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CellContent, DossierIcon } from '../TableStyle';

const CellRecapStatus = ({ bReglementaire, bSpecifique, bPrioritaire }) => {
  return (
    <Table.Cell style={{ color: 'red' }} width={1}>
      {bReglementaire ? (
        <Popup
          position="top center"
          content="Dossier réglementaire"
          trigger={<CellContent centered="true">R</CellContent>}
        />
      ) : null}
      {bSpecifique ? (
        <Popup
          position="top center"
          content="Dossier spécifique"
          trigger={<CellContent centered="true">S</CellContent>}
        />
      ) : null}
      {bPrioritaire ? (
        <Popup
          position="top center"
          content="Dossier prioritaire"
          trigger={
            <CellContent centered="true">
              <DossierIcon name="exclamation circle" />
            </CellContent>
          }
        />
      ) : null}
    </Table.Cell>
  );
};

CellRecapStatus.defaultProps = {
  bReglementaire: 0,
  bSpecifique: 0,
  bPrioritaire: 0
};

CellRecapStatus.propTypes = {
  bReglementaire: PropTypes.number,
  bSpecifique: PropTypes.number,
  bPrioritaire: PropTypes.number
};

export default CellRecapStatus;
