import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  CONFIG_LOAD_SUCCESS,
  CONFIG_LOAD_FAILURE,
  CONFIG_GET_DOSSIERS_SUCCESS,
  CONFIG_GET_DOSSIERS_FAILURE,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE,
  CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_GET_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE,
  CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS,
  CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE,
  SET_DOSSIER_SUCCESS,
  SET_DOSSIER_FAILURE,
  SET_PRIORITE_DOSSIER_SUCCESS,
  SET_PRIORITE_DOSSIER_FAILURE
} from '../constants/ActionTypes';

export function* loadConfig() {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_LOAD_CONFIG Offline');
  } else {
    try {
      const LOAD_CONFIG = gql`
        {
          loadConfig {
            tNatureDossier {
              sPKNature
              sLibelle
            }
            tClient {
              iPKClient
              sNomAbrege
              iPKDossier
              sConcerne
              sFKIntervenant
              dInsert
            }
          }
        }
      `;
      const response = yield call(fetchApollo, LOAD_CONFIG);
      const config = response.data.loadConfig;

      if (config !== null) {
        yield put({ type: CONFIG_LOAD_SUCCESS, payload: config });
      } else {
        yield put({ type: CONFIG_LOAD_FAILURE, payload: 'Erreur de chargement de la config' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement de la config';
          break;
        default:
          break;
      }

      yield put({ type: CONFIG_LOAD_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* setPrioriteDossier({ payload: { iPKDossier, iPriorite } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  try {
    const SET_DOSSIER = gql`mutation
        {
          setPrioriteDossier(iPKDossier: ${iPKDossier}, iPriorite:${iPriorite})  {
            iPKDossier
            iFKModule
            iFKClient
            iFKAffaire
            sFKIntervenant
            sConcerne
            sDescription
            sEtat
            iFKDossierMaitre
            iFKDossierCategorie
            sCategorieDossier
            AssNatureDossier {
              sLibelle
              sPKNature
            }
            AssEtatDossier {
              sLibelle
              iPKDossierEtat
            }
            AssPlanningTasks {
              iPKTask
              sFKIntervenant
              dDebut
              dFin
              AssPlanningTaskType {
                sLibelle
              }
            }
            bReglementaire
            bLourd
            bSpecifique
            bUrgent
            bPrioritaire
            iPriorite
          }
        }
      `;
    const response = yield call(fetchApollo, SET_DOSSIER);
    const dossier = response.data.setPrioriteDossier;

    if (dossier !== null) {
      yield put({ type: SET_PRIORITE_DOSSIER_SUCCESS, payload: { dossier } });
    } else {
      yield put({
        type: SET_PRIORITE_DOSSIER_FAILURE,
        payload: 'Erreur de chargement des dossiers'
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des dossiers';
        break;
      default:
        message = 'Erreur de chargement des dossiers';
        break;
    }

    yield put({ type: SET_PRIORITE_DOSSIER_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
  }
}

export function* setDossierImportant({ payload: { iPKDossier, bUrgent } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  try {
    const SET_DOSSIER = gql`mutation
        {
          setDossierImportant(iPKDossier: ${iPKDossier}, bUrgent:${bUrgent})  {
            iPKDossier
            iFKModule
            iFKClient
            iFKAffaire
            sFKIntervenant
            sConcerne
            sDescription
            sEtat
            iFKDossierMaitre
            iFKDossierCategorie
            sCategorieDossier
            AssNatureDossier {
              sLibelle
              sPKNature
            }
            AssEtatDossier {
              sLibelle
              iPKDossierEtat
            }
            AssPlanningTasks {
              iPKTask
              sFKIntervenant
              dDebut
              dFin
              AssPlanningTaskType {
                sLibelle
              }
            }
            bReglementaire
            bLourd
            bSpecifique
            bUrgent
            bPrioritaire
            bPrive
            iPriorite
          }
        }
      `;
    const response = yield call(fetchApollo, SET_DOSSIER);
    const dossier = response.data.setDossierImportant;

    if (dossier !== null) {
      yield put({ type: SET_DOSSIER_SUCCESS, payload: { dossier } });
    } else {
      yield put({
        type: SET_DOSSIER_FAILURE,
        payload: 'Erreur de chargement des dossiers'
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des dossiers';
        break;
      default:
        message = 'Erreur de chargement des dossiers';
        break;
    }

    yield put({ type: SET_DOSSIER_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
  }
}

export function* getDossiers({ payload: { iPKClient, fullList } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const GET_DOSSIERS = gql`
        {
          getDossiers(iPKClient: ${iPKClient}, fullList: ${fullList})  {
            iPKDossier
            iFKModule
            iFKClient
            iFKAffaire
            sFKIntervenant
            sConcerne
            sDescription
            sEtat
            iFKDossierMaitre
            iFKDossierCategorie
            sCategorieDossier
            AssDevi {
              iPKDevis
              iFKDossier
              sListeMutualisation
            }
            AssNatureDossier {
              sLibelle
              sPKNature
            }
            AssEtatDossier {
              sLibelle
              iPKDossierEtat
            }
            AssPlanningTasks {
              iPKTask
              sFKIntervenant
              dDebut
              dFin
              AssPlanningTaskType {
                sLibelle
              }
            }
            AssParentPlanningTasks {
              iPKTask
              sFKIntervenant
              dDebut
              dFin
              AssPlanningTaskType {
                sLibelle
              }
            }
            bReglementaire
            bLourd
            bSpecifique
            bUrgent
            bPrioritaire
            bPrive
            bMasque
            iPriorite
          }
        }
      `;
      const response = yield call(fetchApollo, GET_DOSSIERS);
      const dossiers = response.data.getDossiers;

      if (dossiers !== null) {
        yield put({ type: CONFIG_GET_DOSSIERS_SUCCESS, payload: { tDossier: dossiers } });
      } else {
        yield put({
          type: CONFIG_GET_DOSSIERS_FAILURE,
          payload: 'Erreur de chargement des dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des dossiers';
          break;
        default:
          message = 'Erreur de chargement des dossiers';
          break;
      }

      yield put({ type: CONFIG_GET_DOSSIERS_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* getDossiersReglementaire() {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const GET_DOSSIERS = gql`
        {
          getDossiersReglementaire {
            iPKDossier
            iFKModule
            iFKClient
            iFKAffaire
            sFKIntervenant
            sConcerne
            sDescription
            sEtat
            iFKDossierMaitre
            iFKDossierCategorie
            sCategorieDossier
            bReglementaire
            bLourd
            bSpecifique
            bUrgent
            bPrioritaire
            bPrive
            bMasque
            iPriorite
            AssEtatDossier {
              iPKDossierEtat
              sLibelle
            }
          }
        }
      `;
      const response = yield call(fetchApollo, GET_DOSSIERS);
      const dossiers = response.data.getDossiersReglementaire;

      if (dossiers !== null) {
        yield put({
          type: CONFIG_GET_DOSSIERS_REGLEMENTAIRE_SUCCESS,
          payload: { tDossier: dossiers }
        });
      } else {
        yield put({
          type: CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE,
          payload: 'Erreur de chargement des dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des dossiers';
          break;
        default:
          message = 'Erreur de chargement des dossiers';
          break;
      }

      yield put({ type: CONFIG_GET_DOSSIERS_REGLEMENTAIRE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* getCategorieDossier({ payload: { iPKClient } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const GET_CATEGORIE_DOSSIER = gql`
        {
          getCategorieDossier(iFKClient: ${iPKClient})  {
            iPKDossierCategorie
            sLibelle
            iFKClient
          }
        }
      `;
      const response = yield call(fetchApollo, GET_CATEGORIE_DOSSIER);
      const categorieDossier = response.data.getCategorieDossier;

      if (categorieDossier !== null) {
        yield put({
          type: CONFIG_GET_CATEGORIE_DOSSIER_SUCCESS,
          payload: { tCategorieDossier: categorieDossier }
        });
      } else {
        yield put({
          type: CONFIG_GET_CATEGORIE_DOSSIER_FAILURE,
          payload: 'Erreur de chargement des categories dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des categories dossiers';
          break;
        default:
          message = 'Erreur de chargement des categories dossiers';
          break;
      }

      yield put({ type: CONFIG_GET_CATEGORIE_DOSSIER_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* addCategorieDossier({ payload: { iFKClient, sLibelle } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const ADD_CATEGORIE_DOSSIER = gql`mutation
        {
          addCategorieDossier(iFKClient: ${iFKClient}, sLibelle: "${sLibelle}")  {
            iPKDossierCategorie
            sLibelle
            iFKClient
          }
        }
      `;
      const response = yield call(fetchApollo, ADD_CATEGORIE_DOSSIER);
      const categorieDossier = response.data.addCategorieDossier;
      console.log(categorieDossier);
      if (categorieDossier !== null) {
        yield put({
          type: CONFIG_ADD_CATEGORIE_DOSSIER_SUCCESS,
          payload: { categorieDossier }
        });
      } else {
        yield put({
          type: CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE,
          payload: 'Erreur de chargement des categories dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des categories dossiers';
          break;
        default:
          message = 'Erreur de chargement des categories dossiers';
          break;
      }

      yield put({ type: CONFIG_ADD_CATEGORIE_DOSSIER_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* editCategorieDossier({ payload: { iPKDossierCategorie, sLibelle } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const EDIT_CATEGORIE_DOSSIER = gql`mutation
        {
          editCategorieDossier(iPKDossierCategorie: ${iPKDossierCategorie}, sLibelle: "${sLibelle}")  {
            iPKDossierCategorie
            sLibelle
            iFKClient
          }
        }
      `;
      const response = yield call(fetchApollo, EDIT_CATEGORIE_DOSSIER);
      const categorieDossier = response.data.editCategorieDossier;

      if (categorieDossier !== null) {
        yield put({
          type: CONFIG_EDIT_CATEGORIE_DOSSIER_SUCCESS,
          payload: { categorieDossier }
        });
      } else {
        yield put({
          type: CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE,
          payload: 'Erreur de chargement des categories dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des categories dossiers';
          break;
        default:
          message = 'Erreur de chargement des categories dossiers';
          break;
      }

      yield put({ type: CONFIG_EDIT_CATEGORIE_DOSSIER_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}

export function* deleteCategorieDossier({ payload: { iPKDossierCategorie } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_GET_DOSSIERS_CONFIG Offline');
  } else {
    try {
      const DELETE_CATEGORIE_DOSSIER = gql`mutation
        {
          deleteCategorieDossier(iPKDossierCategorie: ${iPKDossierCategorie})  {
            iPKDossierCategorie
          }
        }
      `;
      const response = yield call(fetchApollo, DELETE_CATEGORIE_DOSSIER);
      const categorieDossier = response.data.deleteCategorieDossier;

      if (categorieDossier !== null) {
        yield put({
          type: CONFIG_DELETE_CATEGORIE_DOSSIER_SUCCESS,
          payload: { categorieDossier }
        });
      } else {
        yield put({
          type: CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE,
          payload: 'Erreur de chargement des categories dossiers'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des categories dossiers';
          break;
        default:
          message = 'Erreur de chargement des categories dossiers';
          break;
      }

      yield put({ type: CONFIG_DELETE_CATEGORIE_DOSSIER_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}
