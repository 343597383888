import React from 'react';
import { Table, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CellContent } from '../TableStyle';

const CelliPKDossier = ({
  size,
  iPKDossier,
  iFKClient,
  bPrive,
  currentClient,
  iFKDossierMaitre
}) => {
  return (
    <Table.Cell width={1}>
      <CellContent>
        {bPrive && iFKClient !== currentClient && currentClient !== 99 ? (
          <span style={{ color: 'grey' }}>{iPKDossier}</span>
        ) : (
          <>
            <Popup
              content="Ouvre le dossier dans l'Assistance"
              trigger={<a href={`assistancelink://${iPKDossier}`}>{iPKDossier}</a>}
            />
            {iFKDossierMaitre ? (
              <Popup
                content="Ouvre le dossier maître dans l'Assistance"
                trigger={
                  <a
                    style={{
                      marginLeft: size === 'l' ? 5 : 0,
                      display: 'inline-block'
                    }}
                    href={`assistancelink://${iFKDossierMaitre}`}
                  >
                    ({iFKDossierMaitre})
                  </a>
                }
              />
            ) : null}
          </>
        )}
      </CellContent>
    </Table.Cell>
  );
};

CelliPKDossier.defaultProps = {
  iFKDossierMaitre: null
};

CelliPKDossier.propTypes = {
  size: PropTypes.string.isRequired,
  iPKDossier: PropTypes.number.isRequired,
  iFKClient: PropTypes.number.isRequired,
  bPrive: PropTypes.number.isRequired,
  currentClient: PropTypes.number.isRequired,
  iFKDossierMaitre: PropTypes.number
};

export default CelliPKDossier;
