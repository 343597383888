import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import { Grid, Icon, Form, Table, Input, Container } from 'semantic-ui-react';

import Btn from '../../components/Core/Layout/Btn';
import BtnDelete from '../../components/Core/Layout/Btn/Delete';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/datepicker.css';
import 'moment/locale/fr';

import TopBar from '../../components/Core/Layout/TopBar';

import {
  addCategorieDossier,
  editCategorieDossier,
  deleteCategorieDossier
} from '../../actions/config';

const Screen = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.backgroundLight};
`;

const MyContainer = styled(Container)`
  background: ${props => props.theme.colors.backgroundLight};
  width: 90% !important;
  margin-top: 50px;
  padding: 20px 0 0;
`;

const EditFormGroup = styled(Form.Group)`
  margin: 0 !important;
`;

const FormEditContainer = styled.div`
  float: right;
`;

const DateDemarrage = styled.div`
  line-height: 40px;
  float: left;
`;

const AddBtn = styled(Btn)`
  margin-top: 24px;
  height: 36px;
  line-height: 36px;
`;

const EditBtn = styled(Btn)`
  margin-right: 8px;
`;

const Actionbutton = styled.button`
  margin: 0 0 5px 5px;
  padding: 10px;
  padding-left: 35px;
  height: 40px;
  width: 250px;
  text-align: center;
  color: ${props => (props.selected ? props.theme.colors.white : 'inherit')};
  background: ${props => getBackgroundColor(props.theme.colors, props.selected, props.system)};
  border: 1px solid ${props => props.theme.colors.borderLight};
  outline: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueAGIR};
  }
`;

const getBackgroundColor = (colors, selected, system) => {
  if (selected) {
    return colors.blueAGIR;
  }

  if (system) {
    return colors.backgroundLight;
  }

  return colors.white;
};

class Parametres extends Component {
  state = { sLibelle: '', showEditForm: null };

  onHandleChange = e => {
    this.setState({
      sLibelle: e.target.value
    });
  };

  downloadReg = () => {
    const { user } = this.props;
    const pathCustomer = {
      ADS: '\\\\\\\\sql2016\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      SANTELYS: '\\\\\\\\SRVISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ASTEN: '\\\\\\\\I12MES001\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ARAIR: '\\\\\\\\ARAIRISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      APARD: '\\\\\\\\ISADOM-MTP\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ALLP: '\\\\\\\\ALLP-ERP\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ALISEO: '\\\\\\\\VMISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ALAIR: '\\\\\\\\ALAIRVRT\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      AIRRA: '\\\\\\\\SRVISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      AIRCAEN: '\\\\\\\\SQL\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      AGIRADOM: '\\\\\\\\AGIR-ISA1\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe',
      ADEP: '\\\\\\\\ADEPISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe'
    };
    const content = `Windows Registry Editor Version 5.00

[HKEY_CLASSES_ROOT\\assistancelink]
"url protocol"=""

[HKEY_CLASSES_ROOT\\assistancelink\\shell]

[HKEY_CLASSES_ROOT\\assistancelink\\shell\\open]

[HKEY_CLASSES_ROOT\\assistancelink\\shell\\open\\command]
@="\\"${pathCustomer[user.sLogin]}\\"\\"%1\\""`;
    // any kind of extension (.txt,.cpp,.cs,.bat)
    const filename = 'AssistanceLink.reg';

    const blob = new Blob([content], {
      type: 'text/plain;charset=utf-8'
    });

    saveAs(blob, filename);
  };

  onEditHandleChange = (evt, data) => {
    this.setState({
      [data.iPKDossierCategorie]: {
        sLibelle: data.value
      }
    });
  };

  onBtnCategorieAdd = () => {
    const { sLibelle } = this.state;
    const { dispatch, user } = this.props;
    dispatch(addCategorieDossier(user.iFKClient, sLibelle));
  };

  onBtnShowEditForm = (evt, data) => {
    evt.preventDefault();
    this.setState({
      showEditForm: data.iPKDossierCategorie,
      [data.iPKDossierCategorie]: {
        sLibelle: data.sLibelle
      }
    });
  };

  onBtnCategorieDossierEdit = (iPKDossierCategorie, sLibelle) => {
    const { dispatch } = this.props;
    dispatch(editCategorieDossier(iPKDossierCategorie, sLibelle));
  };

  onBtnCategorieDossiereDelete = iPKDossierCategorie => {
    const { dispatch } = this.props;
    dispatch(deleteCategorieDossier(iPKDossierCategorie));
  };

  render() {
    const { sLibelle, showEditForm } = this.state;
    const { tCategorieDossier } = this.props;
    return (
      <Screen>
        <TopBar />
        <MyContainer fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <h1>Gestion des catégories de dossiers</h1>

                <Table celled striped>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan={2}>
                        <Form>
                          <Form.Group>
                            <Form.Field
                              id="sLibelle"
                              control={Input}
                              value={sLibelle}
                              label={<label>Ajouter une catégorie</label>}
                              placeholder="Nom de la catégorie"
                              onChange={this.onHandleChange}
                            />
                            <AddBtn onClick={this.onBtnCategorieAdd}>Ajouter</AddBtn>
                          </Form.Group>
                        </Form>
                      </Table.Cell>
                    </Table.Row>
                    {tCategorieDossier &&
                      tCategorieDossier.map(categorieDossier => (
                        <Table.Row key={categorieDossier.iPKDossierCategorie}>
                          <Table.Cell>
                            <DateDemarrage>{categorieDossier.sLibelle}</DateDemarrage>
                            {showEditForm === categorieDossier.iPKDossierCategorie && (
                              <FormEditContainer>
                                <Form>
                                  <EditFormGroup>
                                    <Form.Field
                                      control={Input}
                                      value={
                                        this.state[categorieDossier.iPKDossierCategorie].sLibelle
                                      }
                                      iPKDossierCategorie={categorieDossier.iPKDossierCategorie}
                                      onChange={this.onEditHandleChange}
                                    />
                                    <Btn
                                      onClick={() => {
                                        this.onBtnCategorieDossierEdit(
                                          categorieDossier.iPKDossierCategorie,
                                          this.state[categorieDossier.iPKDossierCategorie].sLibelle
                                        );
                                      }}
                                    >
                                      Enregistrer
                                    </Btn>
                                  </EditFormGroup>
                                </Form>
                              </FormEditContainer>
                            )}
                          </Table.Cell>
                          <Table.Cell collapsing textAlign="right">
                            <EditBtn onClick={evt => this.onBtnShowEditForm(evt, categorieDossier)}>
                              Modifier
                            </EditBtn>{' '}
                            <BtnDelete
                              onClick={() => {
                                this.onBtnCategorieDossiereDelete(
                                  categorieDossier.iPKDossierCategorie
                                );
                              }}
                            >
                              Supprimer
                            </BtnDelete>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={8}>
                <h1>Télécharger le fichier REG</h1>
                <Actionbutton
                  data-tooltip="Fichier à exécuter sur votre poste, il permet d'ouvrir l'Assistance depuis le navigateur web"
                  data-position="top left"
                  onClick={this.downloadReg}
                >
                  <Icon name="download" /> Télécharger le fichier reg
                </Actionbutton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </MyContainer>
      </Screen>
    );
  }
}

Parametres.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tCategorieDossier: PropTypes.arrayOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  tCategorieDossier: state.config.tCategorieDossier,
  user: state.user.data
});

export default connect(mapStateToProps)(Parametres);
