import axios from 'axios';
import crypto from 'crypto';

export const day2jour = day => {
  const days = {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche'
  };

  return days[day.toLowerCase()];
};

export const day2number = day => {
  const days = {
    lundi: 1,
    mardi: 2,
    mercredi: 3,
    jeudi: 4,
    vendredi: 5,
    samedi: 6,
    dimanche: 7
  };

  return days[day.toLowerCase()];
};

export const month2mois = month => {
  const months = {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  };

  return months[month.toLowerCase()];
};

export const getDayBgColor = (hours, colors, iFKAffectationType, iFKTaskType, dow, morning) => {
  if (iFKAffectationType === 999) {
    // Absence
    return colors.textBlack;
  }

  if (iFKAffectationType === 4) {
    // Absence
    return colors.grey;
  }

  if (iFKAffectationType === 5) {
    // Férié
    return colors.textBlack;
  }

  if (!morning) {
    if (dow === 5) {
      if (hours >= 3) {
        return colors.red;
      }
    } else if (hours >= 4) {
      return colors.red;
    }
  } else if (hours >= 4) {
    return colors.red;
  }

  if (hours >= 2) {
    return colors.orange;
  }

  if (hours > 0) {
    return colors.green;
  }

  return colors.backgroundLight;
};

export const htmlEntitiesDecode = text => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html');
  return dom.body.textContent
    .replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&#13;/g, '');
};

export const truncate = (text, limit, after = '...') => {
  const sourceText = htmlEntitiesDecode(text);

  if (sourceText.length <= limit) return sourceText;

  const words = sourceText.split(' ');
  let truncated = '';

  words.some(word => {
    if (truncated.length + word.length <= limit) {
      truncated += ` ${word}`;

      return false;
    }

    return true;
  });

  return truncated + after;
};

export const setAuthorizationToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const key = JSON.parse(process.env.REACT_APP_CRYPTO_KEY).data;
const iv = JSON.parse(process.env.REACT_APP_CRYPTO_IV).data;

export const encrypt = text => {
  const cipher = crypto.createCipheriv('aes-256-ctr', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { encryptedData: encrypted.toString('hex') };
};

export const decrypt = text => {
  const encryptedText = Buffer.from(text.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-ctr', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

export const importAll = r => {
  const images = {};
  r.keys().map(item => {
    images[item.replace('./', '')] = r(item);
    return null;
  });
  return images;
};

export const trim = (s, c) => {
  if (c === ']') c = '\\]';
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
};
