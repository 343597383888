import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const DossierTableRow = ({
  children,
  size,
  bLourd,
  iPKEtat,
  selected,
  iPKDossier,
  onSelectDossier
}) => {
  let color = null;
  if (size === 'm' && bLourd && iPKEtat !== 15) {
    color = 'red';
  } else if (iPKEtat === 41 || iPKEtat === 43 || iPKEtat === 49) {
    color = 'green';
  } else if (iPKEtat === 15 || iPKEtat === 20) {
    color = 'grey';
  }
  return (
    <Table.Row
      style={{ color }}
      className={selected === iPKDossier ? 'active' : ''}
      onClick={() => onSelectDossier(iPKDossier)}
      key={iPKDossier}
    >
      {children}
    </Table.Row>
  );
};

DossierTableRow.defaultProps = {
  selected: null,
  bLourd: 0,
  iPKEtat: null
};

DossierTableRow.propTypes = {
  onSelectDossier: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  iPKDossier: PropTypes.number.isRequired,
  iPKEtat: PropTypes.number,
  selected: PropTypes.number,
  size: PropTypes.string.isRequired,
  bLourd: PropTypes.number
};

export default DossierTableRow;
