import {
  USER_AUTOLOGIN_REQUEST,
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT_REQUEST
} from '../constants/ActionTypes';

export const autoLogIn = sLogin => ({
  type: USER_AUTOLOGIN_REQUEST,
  payload: { sLogin }
});

export const signUserIn = (sLogin, sPassword) => ({
  type: USER_SIGNIN_REQUEST,
  payload: { sLogin, sPassword }
});

export const signUserOut = () => ({
  type: USER_SIGNOUT_REQUEST,
  payload: { token: null, error: null }
});
