import styled from 'styled-components';
import { Table, Input, Checkbox, Icon } from 'semantic-ui-react';

export const TableBody = styled(Table.Body)`
  max-height: ${props => props.maxheight};
  overflow: ${props => (props.dossierlength > 6 ? 'auto' : 'hidden')};
  overflow-x: hidden;
`;

export const CompactCell = styled(Table.Cell)`
  width: 3.1% !important;
`;

export const CellContent = styled.div`
  padding: 0 5px;
  white-space: initial;
  text-align: ${props => (props.centered === 'true' ? 'center' : 'left')};
`;

export const ColorCheckbox = styled(Checkbox)`
  & label:after {
    color: ${props => props.color} !important;
  }
`;

export const CellInput = styled(Input)`
  & > input {
    padding: 0 !important;
    text-align: center !important;
    line-height: 20px !important;
  }
  width: 35px;
`;

export const DossierIcon = styled(Icon)`
  line-height: 1.1em;
`;
