import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBtnDelete = styled.button`
  display: inline-block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.purpleDark};
  }
`;

const BtnDelete = ({ children, onClick }) => {
  return <StyledBtnDelete onClick={onClick}>{children}</StyledBtnDelete>;
};

BtnDelete.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default BtnDelete;
