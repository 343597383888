import jwtDecode from 'jwt-decode';

import {
  USER_AUTOLOGIN_SUCCESS,
  USER_AUTOLOGIN_FAILURE,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILURE
} from '../constants/ActionTypes';

const loadUserFromToken = token => {
  let user = null;

  try {
    user = jwtDecode(token);
  } catch (err) {
    user = null;
  }

  return user;
};

const guest = {
  iPKUtilisateur: -1,
  sLogin: 'Invité',
  iRole: 0,
  iFKClient: -1,
  sPassword: '',
  dDate: new Date()
};
const user = loadUserFromToken(localStorage.jwtToken);

const initialAuthState = {
  isAuthenticated: user !== null,
  data:
    user !== null
      ? {
          iPKUtilisateur: user.iPKUtilisateur,
          sLogin: user.sLogin,
          iRole: user.iRole,
          iFKClient: user.iFKClient,
          sPassword: '',
          dDate: new Date()
        }
      : guest,
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case USER_AUTOLOGIN_SUCCESS: {
      return { ...state, isAuthenticated: true, data: { ...payload }, error: null };
    }
    case USER_AUTOLOGIN_FAILURE: {
      return { error: payload };
    }

    case USER_SIGNIN_SUCCESS: {
      return { ...state, isAuthenticated: true, data: { ...payload }, error: null };
    }
    case USER_SIGNIN_FAILURE: {
      return { error: payload };
    }

    case USER_SIGNOUT_SUCCESS: {
      return {
        isAuthenticated: false,
        data: guest,
        error: null,
        profile: payload
      };
    }
    case USER_SIGNOUT_FAILURE: {
      return { error: payload };
    }
    default:
      return state;
  }
};
