import { all, takeLatest } from 'redux-saga/effects';
import { signUserIn, signUserOut } from './sagas/user';
import { showLoading, hideLoading } from './sagas/loadings';
import { setOnlineMode, setOfflineMode } from './sagas/network';
import {
  loadConfig,
  getDossiers,
  getDossiersReglementaire,
  getCategorieDossier,
  addCategorieDossier,
  editCategorieDossier,
  deleteCategorieDossier,
  setDossierImportant,
  setPrioriteDossier
} from './sagas/config';

import {
  NETWORK_ONLINE_REQUEST,
  NETWORK_OFFLINE_REQUEST,
  LOADING_ACTIVATE_REQUEST,
  LOADING_DEACTIVATE_REQUEST,
  CONFIG_LOAD_REQUEST,
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT_REQUEST,
  CONFIG_GET_DOSSIERS_REQUEST,
  CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST,
  CONFIG_GET_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST,
  CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST,
  SET_DOSSIER_REQUEST,
  SET_PRIORITE_DOSSIER_REQUEST
} from './constants/ActionTypes';

export default function* rootSaga() {
  yield all([
    takeLatest(NETWORK_ONLINE_REQUEST, setOnlineMode),
    takeLatest(NETWORK_OFFLINE_REQUEST, setOfflineMode),
    takeLatest(LOADING_ACTIVATE_REQUEST, showLoading),
    takeLatest(LOADING_DEACTIVATE_REQUEST, hideLoading),
    takeLatest(CONFIG_LOAD_REQUEST, loadConfig),
    takeLatest(USER_SIGNIN_REQUEST, signUserIn),
    takeLatest(USER_SIGNOUT_REQUEST, signUserOut),
    takeLatest(CONFIG_GET_DOSSIERS_REQUEST, getDossiers),
    takeLatest(CONFIG_GET_DOSSIERS_REGLEMENTAIRE_REQUEST, getDossiersReglementaire),
    takeLatest(CONFIG_GET_CATEGORIE_DOSSIER_REQUEST, getCategorieDossier),
    takeLatest(CONFIG_ADD_CATEGORIE_DOSSIER_REQUEST, addCategorieDossier),
    takeLatest(CONFIG_EDIT_CATEGORIE_DOSSIER_REQUEST, editCategorieDossier),
    takeLatest(CONFIG_DELETE_CATEGORIE_DOSSIER_REQUEST, deleteCategorieDossier),
    takeLatest(SET_DOSSIER_REQUEST, setDossierImportant),
    takeLatest(SET_PRIORITE_DOSSIER_REQUEST, setPrioriteDossier)
  ]);
}
