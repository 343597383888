import React from 'react';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { CompactCell, CellContent, DossierIcon } from '../TableStyle';

const CellbCell = ({ boolean, popupValue, value }) => {
  return (
    <CompactCell style={{ color: 'red' }}>
      {boolean ? (
        <Popup
          position="top center"
          content={`Dossier ${popupValue}`}
          trigger={
            <CellContent centered="true">
              {value === 'P' ? <DossierIcon name="exclamation circle" /> : value}
            </CellContent>
          }
        />
      ) : null}
    </CompactCell>
  );
};

CellbCell.defaultProps = {
  boolean: 0
};

CellbCell.propTypes = {
  boolean: PropTypes.number,
  popupValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default CellbCell;
