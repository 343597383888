import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Container, Grid, Segment, Form, Input } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { signUserIn } from '../actions/user';

import PasswordInput from '../components/Core/Input/PasswordInput';
import logoSrc from '../assets/img/clients/ads.png';

const Screen = styled.div`
  padding-top: 40px;
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.backgroundLight};
`;

const WelcomeText = styled.div`
  padding: 1.5rem 0;
`;

const Control = styled.div`
  display: inline-flex;
  width: 100%;
`;

const ConnexionFormLabel = styled.label`
  padding-right: 1em;
  width: 110px !important;
  line-height: 36px;
  font-weight: 700;
  text-align: right;
`;

const ConnexionFormInput = styled(Input)`
  flex-grow: 1;
`;

const ErrorNotice = styled.div`
  padding: 10px 0 0 110px;
  font-family: 'Futura' !important;
  color: ${props => props.theme.colors.purple};
`;

const ConnexionFormSubmit = styled.button`
  position: relative;
  left: 110px;
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: 250px;
  max-width: 100%;
`;

const TitleApp = styled.b`
  font-size: 16px;
`;

class Connexion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      errors: {}
    };

    this.authentication = false;
  }

  componentDidUpdate() {
    const { history, user } = this.props;

    if (user.isAuthenticated && !this.authentication) {
      if (!this.authentication) {
        this.authentication = true;
        history.push(`/client`);
      }
    }
  }

  onChange = evt => {
    const el = evt.target;
    const { value } = evt.target;
    const { errors } = this.state;
    const newErrors = { ...errors };

    if (errors[el.id]) {
      delete newErrors[el.id];
    }

    this.setState({ [el.id]: value, errors: newErrors });
  };

  checkForm = evt => {
    evt.preventDefault();

    const { login, password } = this.state;
    const errors = {};
    const { dispatch } = this.props;

    if (login.length === 0) {
      errors.login = 'Veuillez saisir votre identifiant.';
    }

    if (password.length === 0) {
      errors.password = 'Veuillez saisir votre mot de passe.';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: { ...errors } });
    } else {
      dispatch(signUserIn(login, password));
    }
  };

  render() {
    const { signInError } = this.props;
    const { login, password, errors } = this.state;

    return (
      <Screen>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={6}>
                <Segment padded>
                  <Logo src={logoSrc} alt="" />
                  <WelcomeText>
                    Bienvenue sur l&apos;application de <TitleApp>suivi des dossiers ADS</TitleApp>.
                    <br />
                    Pour commencer, veuillez vous connecter avec vos identifiants.
                  </WelcomeText>

                  <Form onSubmit={this.checkForm}>
                    <Form.Field inline>
                      <Control>
                        <ConnexionFormLabel htmlFor="login">Identifiant&nbsp;:</ConnexionFormLabel>
                        <ConnexionFormInput
                          id="login"
                          placeholder="Identifiant"
                          autoComplete="login"
                          value={login}
                          onChange={this.onChange}
                          error={!!errors.login}
                        />
                      </Control>
                      {errors.login && <ErrorNotice>{errors.login}</ErrorNotice>}
                    </Form.Field>
                    <Form.Field inline>
                      <PasswordInput
                        password={password}
                        onChange={this.onChange}
                        error={!!errors.password}
                      />
                      {errors.password && <ErrorNotice>{errors.password}</ErrorNotice>}
                    </Form.Field>
                    <Form.Field>
                      <ConnexionFormSubmit primary onClick={this.checkForm}>
                        Connexion
                      </ConnexionFormSubmit>
                      {signInError && <ErrorNotice>{signInError}</ErrorNotice>}
                    </Form.Field>
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Screen>
    );
  }
}

Connexion.defaultProps = {
  signInError: ''
};

Connexion.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  signInError: PropTypes.string,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  isAuthenticated: state.user.isAuthenticated,
  signInError: state.user.error
});

export default withRouter(connect(mapStateToProps)(Connexion));
